import { accountingReportsApi } from '../base';
import { QuickBookPaths } from '@/services/enums';
import { QuickBookConnectParamI, QuickBookGetLoginResponce } from './type';

const reportsApi = accountingReportsApi.injectEndpoints({
  endpoints: builder => ({
    postQuickbookConnect: builder.mutation<any, QuickBookConnectParamI>({
      query: ({ code, state, realmId, workspaceId }) => {
        return {
          url: `${QuickBookPaths.QUICKBOOK_CONNECT}`,
          method: 'POST',
          credentials: 'include',
          body: {
            code,
            state,
            realmId,
            workspaceId
          }
        };
      }
    }),
    getQuickbooksLogin: builder.mutation<QuickBookGetLoginResponce, number | undefined>({
      query: currentWorkspaceId => {
        return {
          url: `${QuickBookPaths.QUICKBOOK_LOGIN}`,
          method: 'GET',
          credentials: 'include',
          params: {
            workspaceId: currentWorkspaceId
          }
        };
      }
    })
  })
});

export const { usePostQuickbookConnectMutation, useGetQuickbooksLoginMutation } = reportsApi;
