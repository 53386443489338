import { Button } from '@mui/material';
import qboButton from '@/assets/images/CQBmedium.svg';

type QuickBooksButtonProps = {
  onClick: () => void;
};

const QuickBooksButton = ({ onClick }: QuickBooksButtonProps) => {
  return (
    <Button
      fullWidth
      variant="contained"
      onClick={onClick}
      sx={{
        borderRadius: '12px',
        height: '44px',
        backgroundImage: `url(${qboButton})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: '#2CA01C',
        '&:hover': { backgroundColor: '#2CA01C' }
      }}
    ></Button>
  );
};

export default QuickBooksButton;
