import React, { FC } from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { ESortOrder } from '@/utils/enums';
import SwitchVerticalIcon from '@/components/icons/SwitchVertical';
import { TBaseTableHeadProps } from './baseTableHead.types';

const BaseTableHead: FC<TBaseTableHeadProps> = ({
  sortKey,
  columns = [],
  onSortOrderChange,
  sortOrder
}) => {
  const handleSortingChange = (accessor: string) => {
    const newSortOrder =
      accessor === sortKey && sortOrder === ESortOrder.ASC ? ESortOrder.DESC : ESortOrder.ASC;
    onSortOrderChange && onSortOrderChange(accessor, newSortOrder);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map(({ label, accessor, sortable, props = {} }) => {
          const direction =
            sortKey === accessor && sortOrder === ESortOrder.ASC ? sortOrder : ESortOrder.DESC;

          return sortable ? (
            <TableCell
              key={accessor}
              sortDirection={sortKey === accessor ? sortOrder : false}
              {...props}
            >
              <TableSortLabel
                active={true}
                direction={direction}
                onClick={() => handleSortingChange(accessor)}
                IconComponent={SwitchVerticalIcon}
              >
                <Typography whiteSpace="nowrap" fontWeight="bold">
                  {label}
                </Typography>
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={accessor} {...props}>
              <Typography whiteSpace="nowrap" fontWeight="bold">
                {label}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default BaseTableHead;
