import PageLoader from '@/components/PageLoader/PageLoader';
import React, { useEffect } from 'react';
import { useAuth } from '@/contexts/AuthContext';

const ConnectToQuickBooks = () => {
  const { loginWithQuickbooks } = useAuth();

  useEffect(() => {
    loginWithQuickbooks();
  }, []);

  return <PageLoader />;
};

export default ConnectToQuickBooks;
