import React from 'react';

function AttentionIcon({ fill, stroke, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="58" height="64" viewBox="0 0 58 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29 20V32M29 44H29.03M2 19.8243V44.1757C2 45.2036 2 45.7176 2.15145 46.176C2.28544 46.5815 2.50447 46.9537 2.7939 47.2678C3.12106 47.6228 3.57034 47.8724 4.46892 48.3716L26.6689 60.705C27.5197 61.1776 27.9451 61.414 28.3956 61.5066C28.7944 61.5886 29.2056 61.5886 29.6044 61.5066C30.0549 61.414 30.4803 61.1776 31.3311 60.705L53.5311 48.3716C54.4297 47.8724 54.8789 47.6228 55.2061 47.2678C55.4955 46.9537 55.7146 46.5815 55.8485 46.176C56 45.7176 56 45.2036 56 44.1757V19.8243C56 18.7964 56 18.2825 55.8485 17.8241C55.7146 17.4185 55.4955 17.0463 55.2061 16.7322C54.8789 16.3772 54.4297 16.1276 53.5311 15.6284L31.3311 3.29506C30.4803 2.82238 30.0549 2.58605 29.6044 2.49339C29.2056 2.41138 28.7944 2.41138 28.3956 2.49339C27.9451 2.58605 27.5197 2.82238 26.6689 3.29506L4.46891 15.6284C3.57034 16.1276 3.12106 16.3772 2.7939 16.7322C2.50447 17.0463 2.28544 17.4185 2.15145 17.8241C2 18.2825 2 18.7964 2 19.8243Z"
        stroke={stroke || '#333840'}
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default AttentionIcon;
