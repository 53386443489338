import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import { ActiveButton } from '../ui';
import { IntervalI, INTERVALS } from './type';
import { Theme } from '@emotion/react';
import TimeInput from '@/components/TimeInput';

const radioStyles = {
  display: 'grid',
  gridTemplateColumns: { xs: '1fr', sm: 'repeat(3, 1fr)' },
  gap: '.5rem'
};

const boxStyles = {
  display: 'grid',
  gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
  gap: '2rem'
};

export const daysOfWeek = [
  {
    label: 'Monday',
    value: 1
  },
  {
    label: 'Tuesday',
    value: 2
  },
  {
    label: 'Wednesday',
    value: 3
  },
  {
    label: 'Thursday',
    value: 4
  },
  {
    label: 'Friday',
    value: 5
  },
  {
    label: 'Saturday',
    value: 6
  },
  {
    label: 'Sunday',
    value: 7
  }
];

const MIN_HOUR = 1;
const MAX_HOUR = 23;

interface SelectIntervalSectionProps {
  selectInterval: IntervalI;
  setSelectInterval: (value: INTERVALS) => void;
  hourOfDay: number;
  setHourOfDay: (value: number) => void;
  dayOfWeek: number[];
  setDayOFWeek: (value: number[]) => void;
  dayOfMonth: number[];
  setDayOfMonth: (value: number[]) => void;
  selectCompanies: number[];
  setSelectCompanies: (value: number[]) => void;
  titleSx?: SxProps<Theme>;
}

export const SelectIntervalSection = ({
  selectInterval,
  setSelectInterval,
  hourOfDay,
  setHourOfDay,
  dayOfWeek,
  setDayOFWeek,
  selectCompanies,
  setSelectCompanies,
  dayOfMonth,
  setDayOfMonth,
  titleSx
}: SelectIntervalSectionProps) => {
  const handleChangeDayOfWeek = (event: SelectChangeEvent<typeof dayOfWeek>) => {
    const {
      target: { value }
    } = event;
    setDayOFWeek(typeof value !== 'string' ? value.map((item: string | number) => +item) : []);
  };

  const handleChangeDayOfMonth = (event: SelectChangeEvent<typeof dayOfWeek>) => {
    const {
      target: { value }
    } = event;
    setDayOfMonth(typeof value !== 'string' ? value.map((item: string | number) => +item) : []);
  };

  const onHandlerChangeSelectCompanies = (event: SelectChangeEvent<typeof selectCompanies>) => {
    const {
      target: { value }
    } = event;
    setSelectCompanies(
      typeof value !== 'string' ? value.map((item: string | number) => +item) : []
    );
  };

  const onHandlerChangeHourOfDay = (hour: string) => {
    if (+hour > MAX_HOUR || +hour < MIN_HOUR) {
      return;
    }
    setHourOfDay(+hour);
  };

  return (
    <>
      <Stack direction="column" gap="1rem">
        <Stack direction="column" gap="1rem">
          <Typography sx={titleSx}>Select interval</Typography>
          <Box sx={radioStyles}>
            <ActiveButton
              label="Daily"
              active={selectInterval === INTERVALS.DAILY}
              onClick={() => setSelectInterval(INTERVALS.DAILY)}
            />
            <ActiveButton
              label="Weekly"
              active={selectInterval === INTERVALS.WEEKLY}
              onClick={() => setSelectInterval(INTERVALS.WEEKLY)}
            />
            <ActiveButton
              label="Monthly"
              active={selectInterval === INTERVALS.MONTHLY}
              onClick={() => setSelectInterval(INTERVALS.MONTHLY)}
            />
            {/* <ActiveButton label="Manual" /> */}
          </Box>
        </Stack>
      </Stack>

      <Stack direction="column" gap="1rem">
        <Box
          sx={{
            ...boxStyles,
            gridTemplateColumns: { xs: '1fr', sm: 'repeat(8, 1fr)' }
          }}
        >
          {selectInterval === INTERVALS.WEEKLY && (
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              gap="0.75rem"
              minWidth={'200px'}
            >
              <Typography
                sx={{
                  textAlign: 'start',
                  width: '100%',
                  fontSize: { xs: '12px', sm: '14px' },
                  color: '#393A3DA3',
                  ...(dayOfWeek.length ? {} : { color: 'red' })
                }}
                variant="h5"
              >
                Days of week
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  gap: 2,
                  width: '100%'
                }}
              >
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={dayOfWeek}
                  onChange={handleChangeDayOfWeek}
                  style={{
                    width: '100%'
                    // ...(dayOfWeek.length ? {} : { border: '1px solid red', outline: 'none' })
                  }}
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 150 } }
                  }}
                >
                  {daysOfWeek.map(dayOfWeek => (
                    <MenuItem key={dayOfWeek.value} value={dayOfWeek.value}>
                      {dayOfWeek.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Stack>
          )}

          {selectInterval === INTERVALS.MONTHLY && (
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              gap="0.75rem"
              minWidth={'200px'}
            >
              <Typography
                sx={{
                  textAlign: 'start',
                  width: '100%',
                  fontSize: { xs: '12px', sm: '14px' },
                  color: '#393A3DA3',
                  ...(dayOfMonth.length ? {} : { color: 'red' })
                }}
                variant="h5"
              >
                Days of month
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  gap: 2,
                  width: '100%'
                }}
              >
                <Select
                  labelId="demo-multiple-month-label"
                  id="demo-multiple-month"
                  multiple
                  value={dayOfMonth}
                  onChange={handleChangeDayOfMonth}
                  style={{
                    width: '100%'
                  }}
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 150 } }
                  }}
                >
                  {[...Array(31)].map((_, index) => (
                    <MenuItem key={`month-${index}`} value={index++}>
                      {index++}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Stack>
          )}

          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            gap="0.75rem"
            minHeight={'84px'}
          >
            <Typography
              sx={{
                textAlign: 'start',
                width: '100%',
                fontSize: { xs: '12px', sm: '14px' },
                color: '#393A3DA3'
              }}
              variant="h5"
            >
              Hour of day
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                gap: 2,
                width: '100%'
              }}
            >
              <TimeInput defaultValueHourOfDay={hourOfDay} onChange={onHandlerChangeHourOfDay} />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
