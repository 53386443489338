import * as React from 'react';
import { PropsWithChildren } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { createTheme } from '@/styles/theme/create-theme';

function ThemeProvider({ children }: PropsWithChildren): React.JSX.Element {
  const theme = createTheme();

  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      {children}
    </CssVarsProvider>
  );
}

export default ThemeProvider;
