import { Box } from '@mui/material';
import { WorkSpaceMenuItem } from './workSpaceMenuItem';
import { ManageWorkspaceMenuProps } from './type';

const ManageWorkspaceMenu = ({ items, onClick }: ManageWorkspaceMenuProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: '#D0D0D01A',
        // backgroundColor: 'var(--mui-palette-neutral-950)',
        // border: '1px solid var(--mui-palette-neutral-700)',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
        // p: '4px 12px'
      }}
    >
      {
        items.map((item, index) => {
          return <WorkSpaceMenuItem key={`${item.name}-${index}`} item={item} onClick={onClick} />;
        })
      }
     
    </Box>
  );
};

export default ManageWorkspaceMenu;