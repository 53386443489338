import { BoxProps, Paper, PaperProps, styled, TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ModalWrapper = styled(Box, {
  name: 'WorkspacesModalWrapper',
  slot: 'Root'
})<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '24',
  borderRadius: '12px'
}));

export const ModalContent = styled(Paper, {
  name: 'WorkspacesModalContent',
  slot: 'Root'
})<PaperProps>(() => ({
  padding: '24px',
  width: '500px'
}));

export const HeadlineWrapper = styled(Box, {
  name: 'WorkspacesModalHeadlineWrapper',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const ImageWrapper = styled(Box, {
  name: 'WorkspacesModalImageWrapper',
  slot: 'Root'
})<BoxProps>(() => ({
  color: '#0078C8',
  textAlign: 'center'
}));

export const DescriptionText = styled(Typography, {
  name: 'WorkspacesModalDescriptionText',
  slot: 'Root'
})<TypographyProps>(({ theme }) => ({
  marginTop: '8px',
  width: '270px',
  fontSize: '16px',
  color: theme.palette.text.secondary
}));

export const BodyWrapper = styled(Box, {
  name: 'WorkspacesModalBodyWrapper',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '32px 24px 0',
  justifyContent: 'space-between'
}));

export const NotFoundText = styled(Typography, {
  name: 'WorkspacesModalNotFoundText',
  slot: 'Root'
})<TypographyProps>(() => ({
  textAlign: 'center',
  padding: '20px 0'
}));
