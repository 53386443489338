import React, { ChangeEvent, FC, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

type TCheckBoxInput = {
  label: string;
  onChecked?: (isChecked: boolean) => void;
  defaultValue?: boolean;
  isError?: boolean;
};

const CheckBoxInput: FC<TCheckBoxInput> = ({
  label,
  onChecked,
  defaultValue = false,
  isError = false
}) => {
  const [checked, setChecked] = useState(defaultValue);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChecked && onChecked(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            sx={{ color: isError ? 'rgb(244, 67, 54)' : '#393A3DA3' }}
          />
        }
        sx={{
          fontSize: { xs: '12px', sm: '14px' },
          fontWeight: '600',
          color: isError ? 'rgb(244, 67, 54)' : 'inherit'
        }}
        label={label}
      />
    </FormGroup>
  );
};

export default CheckBoxInput;
