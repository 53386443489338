import { Link } from 'react-router-dom';
import { Avatar, Box, Divider, Link as MUILink, Paper, Stack, Typography } from '@mui/material';

import { paths } from '@/utils/paths';
import { Helmet } from 'react-helmet';
import { config } from '@/config';
import useSession from '@/hooks/useSession';
import { useInviteTokenHandling } from '@/hooks/useInviteTokenHandling';
import { useAuth } from '@/contexts/AuthContext';
import UserCard from '@/components/UserCard';
import Button from '@/components/buttons/Button';

const SignupStep3Page = () => {
  const { user } = useSession();
  const { signOut } = useAuth();
  const { invitationDatails, handleAcceptInvite } = useInviteTokenHandling({
    useLocalStorage: true
  });

  const { workspace } = invitationDatails || {};

  const navigateToSignUp = () => {
    signOut();
  };

  return (
    <Box
      sx={{
        paddingX: { xs: 0, sm: '82px' },
        paddingY: { xs: 2, sm: '72px' }
      }}
    >
      <Box sx={{ mx: { xs: 2 } }}>
        <Typography variant="h5">ClearSheets</Typography>
      </Box>
      <Paper
        sx={{
          maxWidth: '474px',
          mx: { xs: 2, sm: 'auto' },
          my: '50px',
          padding: '48px 32px 32px 32px',
          borderRadius: '12px'
        }}
        elevation={9}
      >
        <Helmet>
          <title>Invitation | {config.site.name}</title>
        </Helmet>
        <Stack gap="32px" sx={{ textAlign: 'center' }}>
          <Stack direction="row" gap="16px" justifyContent="center">
            <Avatar
              sx={{
                width: 72,
                height: 72,
                background: '#D6E9F6',
                fontWeight: 500,
                fontSize: '36px',
                color: 'black'
              }}
            >
              {workspace?.name?.charAt(0)?.toUpperCase()}
            </Avatar>
          </Stack>
          <Stack>
            <Typography
              sx={{ fontSize: { xs: '16px', sm: '24px' }, fontWeight: 700, lineHeight: '30px' }}
              gutterBottom
            >
              You are invited to Workspace
            </Typography>
            <Typography
              justifyContent={'center'}
              sx={{ fontSize: { xs: '14px', sm: '16px' }, color: '#393A3DA3', fontWeight: 400 }}
            >
              Select an account to go to{' '}
              <Link to={paths.auth.signIn} style={{ boxShadow: 'none' }}>
                <MUILink sx={{ textDecoration: 'none', color: '#0078C8', fontWeight: 600 }}>
                  {workspace?.name}
                </MUILink>
              </Link>
            </Typography>
          </Stack>
          <UserCard user={user} onClick={() => handleAcceptInvite({ redirectUrl: paths.home })} />
          <Divider>
            <Typography sx={{ color: '#393A3D' }}>or</Typography>
          </Divider>
          <Button
            onClick={navigateToSignUp}
            title="Sign up"
            backgroundColor="#0078C8"
            styles={{
              '&:hover': { backgroundColor: '#0B789B' },
              width: '100%',
              border: 'solid 1px #12182620',
              borderRadius: '12px',
              py: '10px'
            }}
          />
        </Stack>
      </Paper>
    </Box>
  );
};

export default SignupStep3Page;
