import { ManagmentButtonsProps } from '@/components/buttons/type';
import editIcon from '@/assets/icons/editWhite.svg';
import Button from '@/components/buttons/Button';

const ButtonEdit = ({ onClick, title, ...rest }: ManagmentButtonsProps) => {
  return (
    <Button
      title={title || 'Edit'}
      onClick={onClick}
      icon={<img src={editIcon} alt="editIcon" />}
      {...rest}
    />
  );
};

export default ButtonEdit;
