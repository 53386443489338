import React, { memo } from 'react';

import { CombineTablePagination, TCombinedTableProps } from '@/components';
import * as S from './combinedTable.styled';
import BaseTable from '../baseTable/BaseTable';
import AppCard from '../appCard/AppCard';

const CombinedTable = memo(
  ({
    paginationProps = {},
    TableToolbar,
    isPaginationDisabled = false,
    paginate = true,
    sx,
    ...tableProps
  }: TCombinedTableProps) => {
    const { onPageChange, ...restPaginationProps } = paginationProps;
    const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
      onPageChange && onPageChange(page);
    };

    const paginationMarkup = (
      <S.CombinedTableFooter>
        <CombineTablePagination
          disabled={isPaginationDisabled}
          onChange={handlePageChange}
          {...restPaginationProps}
          variant="outlined"
          shape="rounded"
        />
      </S.CombinedTableFooter>
    );

    return (
      <S.CombinedTable>
        <AppCard>
          <S.CombinedTableMain sx={sx}>
            <S.CombinedTableToolbar>{TableToolbar}</S.CombinedTableToolbar>
            <S.CombinedTableBody>
              <BaseTable {...tableProps} />
            </S.CombinedTableBody>
          </S.CombinedTableMain>
        </AppCard>

        {paginate ? paginationMarkup : null}
      </S.CombinedTable>
    );
  }
);

export default CombinedTable;
