import React, { CSSProperties, FC } from 'react';
import { ReportStepperStyled } from '@/components/reportStepper/reportStepper.styled';
import BaseStepper, { TBaseStepperProps } from '../ui/baseStepper/BaseStepper';

export type TReportStepperProps = TBaseStepperProps & { activeStep: number, containerStyle?: CSSProperties };

const ReportStepper: FC<TReportStepperProps> = ({ steps, activeStep, containerStyle }) => {
  return (
    <ReportStepperStyled style={containerStyle}>
      <BaseStepper steps={steps} activeStep={activeStep} />
    </ReportStepperStyled>
  );
};

export default ReportStepper;
