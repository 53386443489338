import { Auth0User } from '@/types';
import { Avatar, Stack, Typography } from '@mui/material';

type Props = {
  user: Auth0User | undefined;
  onClick?: () => void;
};

const UserCard = ({ user, onClick }: Props) => {
  return (
    <Stack
      onClick={onClick}
      sx={{
        background: '#D4D7DB52',
        borderRadius: '12px',
        padding: '8px 22px 8px 16px',
        cursor: 'pointer'
      }}
      direction="row"
      gap={2}
    >
      <Avatar src={user?.picture} />
      <Stack sx={{ textAlign: 'start' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{user?.nickname}</Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#393A3D' }}>
          {user?.email}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UserCard;
