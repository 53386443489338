export const WORKSPACE_ROLES = Object.freeze({
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  MAINTAINER: 'MAINTAINER',
  USER: 'USER'
} as const);

export const REPORT_TEMPLATE_PERMISSIONS = Object.freeze({
  REPORT_TEMPLATE_CREATE: 'REPORT_TEMPLATE_CREATE',
  REPORT_TEMPLATE_READ: 'REPORT_TEMPLATE_READ',
  REPORT_TEMPLATE_UPDATE: 'REPORT_TEMPLATE_UPDATE',
  REPORT_TEMPLATE_DELETE: 'REPORT_TEMPLATE_DELETE'
} as const);

export const REPORT_PERMISSIONS = Object.freeze({
  REPORT_CREATE: 'REPORT_CREATE',
  REPORT_READ: 'REPORT_READ',
  REPORT_UPDATE: 'REPORT_UPDATE',
  REPORT_DELETE: 'REPORT_DELETE'
} as const);

export const WORKSPACE_PERMISSIONS = Object.freeze({
  ...REPORT_TEMPLATE_PERMISSIONS,
  ...REPORT_PERMISSIONS,
  WORKSPACE_UPDATE: 'WORKSPACE_UPDATE',
  WORKSPACE_DELETE: 'WORKSPACE_DELETE'
} as const);

export const WORKSPACE_PERMISSIONS_BY_ROLE = Object.freeze({
  [WORKSPACE_ROLES.OWNER]: [...Object.values(WORKSPACE_PERMISSIONS)],
  [WORKSPACE_ROLES.ADMIN]: [
    ...Object.values(WORKSPACE_PERMISSIONS),
    ...Object.values(REPORT_PERMISSIONS),
    WORKSPACE_PERMISSIONS.WORKSPACE_UPDATE
  ],
  [WORKSPACE_ROLES.MAINTAINER]: [
    ...Object.values(WORKSPACE_PERMISSIONS),
    ...Object.values(REPORT_PERMISSIONS)
  ],
  [WORKSPACE_ROLES.USER]: [
    WORKSPACE_PERMISSIONS.REPORT_TEMPLATE_READ,
    WORKSPACE_PERMISSIONS.REPORT_READ
  ]
} as const);
