import { useCallback } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Avatar, Box, Typography } from '@mui/material';
import { WorkSpaceMenuItemProps } from './type';
import { AvatarStyle } from '../avatar';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

export const WorkSpaceMenuItem = ({item, onClick}: WorkSpaceMenuItemProps) => {
  const getAvatar = useCallback(() => {
    if(!item.letter) {
      return <></>;
    }

    switch (item.letter) {
      case 'pen':
        return <Avatar sx={{ ...AvatarStyle }}> 
          <ModeEditOutlineOutlinedIcon sx={{
            width: '16px',
            height: '16px'
          }} /></Avatar>;
      default:
        return <Avatar sx={{ ...AvatarStyle }}>{item.letter}</Avatar>;
    }
  }, [item.letter]);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'center', 
        width: '100%', 
        gap: 1,
        py: 1,
        p: '4px 12px',
        cursor: 'pointer',
        backgroundColor: item.isChoose ? '#D0D0D03D' : undefined
      }}
      onClick={() => onClick && onClick(item.id)}
    >
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          alignItems: 'center', 
          width: '100%', 
          gap: 1,
          py: 1
        }}
      >
        {getAvatar()}
        <Box>
          <Typography color="inherit" sx={{ fontSize: '16px' }}>
            {item.name}
          </Typography>
          {
            item.subTitle && (
              <Typography color="inherit" sx={{ fontSize: '14px' }}>
                {item.subTitle}
              </Typography>
            )
          }

        </Box>
      </Box>
      {
        item.isChoose && <CheckIcon />
      }
    </Box>
  );
};