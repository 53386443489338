import { useSelector } from 'react-redux';
import useSession from '@/hooks/useSession';
import { getWorkspaces } from '@/slices';
import { useMemo } from 'react';
import { TWorkespace } from '@/types/workspace';

export const useCurrentWorkspace = (): TWorkespace | undefined => {
  const { currentWorkspaceId } = useSession();
  const workspaces = useSelector(getWorkspaces);

  return useMemo(
    () => workspaces.find(({ id }) => id === currentWorkspaceId),
    [workspaces, currentWorkspaceId]
  );
};
