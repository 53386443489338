import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const DeleteModalStyled = styled(Box, {
  name: 'DeleteModal',
  slot: 'Root'
})<BoxProps>(() => ({
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  width: '29.125rem'
}));

export const DeleteModalHeaderStyled = styled(Box, {
  name: 'DeleteModalHeader',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'center'
}));

export const DeleteModalBodyStyled = styled(Box, {
  name: 'DeleteModalBody',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem'
}));

export const DeleteModalFooterStyled = styled(Box, {
  name: 'DeleteModalFooter',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  gap: '1rem'
}));
