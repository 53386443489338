export default `1. **Information We Collect**  
We may collect the following types of information:  

a) Personal Information  
Personally identifiable information, such as your name, email address, phone number, and other contact or identifiable information that you voluntarily provide to us when registering on the website, making a purchase, or otherwise interacting with our services.

b) Usage Data  
Information automatically collected when you visit our website or use our services, including your IP address, browser type, operating system, access times, and pages viewed. We may also collect data on how you interact with the website (e.g., clicks, scrolls).

c) Cookies and Tracking Technologies  
We use cookies, web beacons, and similar tracking technologies to collect and store certain information. Cookies help us improve the functionality and performance of our website and provide a better user experience. You may opt to disable cookies through your browser settings, but this may limit your access to certain parts of the website.

2. **How We Use Your Information**  
We use the information we collect in the following ways:  

- To provide services: To deliver, maintain, and improve our services and products.
- To communicate: To send you important updates, newsletters, promotions, or respond to inquiries and requests.
- To personalize experience: To tailor your website experience based on your preferences.
- To process transactions: To manage orders, payments, and provide customer support.
- For analytics: To monitor and analyze website traffic, trends, and performance.
- To comply with legal obligations: To fulfill any regulatory requirements or court orders.
3. **How We Share Your Information**  
We may share your information in the following situations:  

a) With Service Providers  
We may share information with third-party vendors, consultants, or service providers who perform services on our behalf, such as payment processing, hosting, customer support, or marketing.

b) For Legal Reasons  
We may disclose information if required by law, such as in response to a court order, government request, or to protect our rights and safety.

c) Business Transfers  
In the event of a merger, sale, or acquisition of all or part of our business, we may transfer your information as part of the transaction.

4. **Data Security**  
We use reasonable organizational, technical, and administrative measures to safeguard your personal information. However, no method of transmission over the Internet is 100% secure, and we cannot guarantee the absolute security of your information.

5. **Your Data Rights**  
Depending on your location, you may have the following rights regarding your personal information:  

- Access: Request access to the information we hold about you.
- Correction: Request correction of inaccurate or incomplete information.
- Deletion: Request the deletion of your personal data, subject to legal or contractual obligations.
- Opt-Out: Unsubscribe from marketing communications by following the opt-out instructions provided in emails or contacting us.

6. **Third-Party Links**  
Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such websites, and we encourage you to review their privacy policies.

7. **International Data Transfers**  
If you are accessing our services from outside United Stated, your information may be transferred to and processed in United Stated. By using our services, you consent to such transfers and processing.

8. **Children's Privacy**  
Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If we discover that we have inadvertently collected personal information from a child, we will take steps to delete the data as soon as possible.

9. **Changes to This Privacy Policy**  
We may update this Privacy Policy from time to time to reflect changes in our practices, technologies, or legal obligations. We will notify you of any material changes by posting the new Privacy Policy on our website with an updated "Effective Date."`;
