import ReactMarkdown from 'react-markdown';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import agreement from './agreement';
import Container from '@mui/material/Container';

const LicenseAgreement = () => {
  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexGrow: 1
      }}
    >
      <Container maxWidth="lg">
        <Typography>
          <ReactMarkdown>{agreement}</ReactMarkdown>
        </Typography>
      </Container>
    </Box>
  );
};

export default LicenseAgreement;
