import React, { forwardRef, Ref } from 'react';

const ChevronDownIcon = forwardRef<HTMLDivElement, React.SVGProps<SVGSVGElement>>(
  ({ fill, ...props }, ref: Ref<HTMLDivElement>) => {
    return (
      <div ref={ref}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill={fill || 'currentColor'}
            d="M12.768 14.078a1 1 0 01-1.536 0L8.367 10.64A1 1 0 019.135 9h5.73a1 1 0 01.768 1.64l-2.865 3.438z"
          ></path>
        </svg>
      </div>
    );
  }
);

export default ChevronDownIcon;
