import { Button } from '@mui/material';
import intuitButton from '@/assets/images/IntuitMedium.svg';

type Props = {
  onClick: () => void;
};

const IntuitButton = ({ onClick }: Props) => {
  return (
    <Button
      fullWidth
      variant="contained"
      onClick={onClick}
      sx={{
        borderRadius: '12px',
        height: '44px',
        backgroundImage: `url(${intuitButton})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: '#0077C5',
        '&:hover': { backgroundColor: '#0077C5' }
      }}
    ></Button>
  );
};

export default IntuitButton;
