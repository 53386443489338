import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import authSvg from '@/assets/icons/auth.svg';

export type LayoutProps = {
  children: React.ReactNode;
};

export function AuthLayout({ children }: LayoutProps): React.JSX.Element {
  return (
    <Box
      sx={{
        display: { xs: 'flex', lg: 'grid' },
        flexDirection: 'column',
        gridTemplateColumns: '1fr 1fr',
        minHeight: '100%'
      }}
    >
      <Box
        sx={{
          color: 'var(--mui-palette-common-white)',
          display: { xs: 'none', lg: 'flex' },
          height: '100%',
          width: '100%'
        }}
      >
        <img src={authSvg} style={{ height: '100%' }} alt="authSvg" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ pt: 5, mx: 'auto' }}>
            <Typography variant="h5">ClearSheets</Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              p: 3,
              marginTop: '117px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '500px',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
