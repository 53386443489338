export default `1. **Grant of License**  
Licensor hereby grants Licensee a limited, non-exclusive, non-transferable license to use the ClearSheets service (the "Licensed Material") solely for personal use, subject to the terms of this Agreement.

2. **License Restrictions**  
The Licensee shall not:

- Sell, sublicense, or otherwise transfer the Licensed Material to any third party without prior written consent from the Licensor.
- Modify, reverse engineer, decompile, or disassemble the Licensed Material.
- Use the Licensed Material in a manner that violates any applicable laws or regulations.
3. **Ownership**  
All rights, titles, and interest in the Licensed Material, including any intellectual property rights, are and will remain the sole property of the Licensor. Licensee acknowledges that they have no ownership or rights beyond the license granted in this Agreement.

4. **Term and Termination**  
This Agreement is effective as of the Effective Date and will remain in effect until terminated. Licensor may terminate this Agreement immediately if Licensee breaches any term of this Agreement. Upon termination, Licensee must cease all use of the Licensed Material and destroy any copies in their possession or control.

5. **Fees and Payment (If Applicable)**  
Licensee agrees to pay Licensor the fee of [Fee Amount] for the licensed use of the Licensed Material. Payment shall be made [payment terms], and late payments may result in additional interest charges or termination of the license.

6. **Warranty Disclaimer**  
The Licensed Material is provided "AS IS" without warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. Licensor does not warrant that the Licensed Material will be free from errors or uninterrupted.

7. **Limitation of Liability**  
In no event shall the Licensor be liable for any indirect, incidental, consequential, or punitive damages arising from the use of the Licensed Material, even if the Licensor has been advised of the possibility of such damages.

8. **Indemnification**  
Licensee agrees to indemnify, defend, and hold harmless the Licensor from and against any claims, liabilities, damages, losses, or expenses arising from Licensee's use of the Licensed Material.

9. **Governing Law**  
This Agreement shall be governed by and construed in accordance with the laws of the state of [State/Country], without regard to its conflict of laws provisions.

10. **Entire Agreement**  
This Agreement constitutes the entire agreement between the parties regarding the Licensed Material and supersedes any prior agreements, understandings, or communications, whether written or oral, related to the subject matter.

11. **Amendments**  
This Agreement may only be modified by a written amendment signed by both parties.

12. **Severability**  
If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.

13. **Notices**  
All notices required or permitted under this Agreement shall be in writing and delivered to the addresses of the parties set forth above.`;
