import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Auth0User } from '@/types';
import { SessionState } from '@/types/store';

export interface InitFlow {
  isFirstConnect?: boolean;
  isRedirectAfterConnectToReportCreate?: boolean;
}

export const initialState: SessionState = {
  accessToken: undefined,
  initFlow: undefined,
  user: undefined,
  appState: undefined,
  expiresIn: undefined,
  idToken: undefined,
  idTokenPayload: undefined,
  refreshToken: undefined,
  scope: undefined,
  state: undefined,
  tokenType: undefined,
  workspaces: [],
  invitations: [],
  currentWorkspaceId: undefined
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Partial<SessionState> | undefined>) => {
      if (!action.payload) {
        return initialState;
      }

      return {
        ...state,
        ...action.payload
      };
    },
    setUser: (state, action: PayloadAction<Auth0User | undefined>) => {
      if (!action.payload) {
        return state;
      }

      state.user = action.payload;

      return state;
    },
    setInitFlow: (state, action: PayloadAction<InitFlow | undefined>) => {
      state.initFlow = action.payload;

      return state;
    },
    setCurrentWorkspaceId: (state, action: PayloadAction<number>) => {
      state.currentWorkspaceId = action.payload;

      return state;
    }
  }
});

export const { setSession, setUser, setInitFlow, setCurrentWorkspaceId } = sessionSlice.actions;

export default sessionSlice.reducer;
