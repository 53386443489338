import React from 'react';

function FileQuestionIcon({ fill, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M60 28.5V20.4C60 15.3595 60 12.8393 59.0191 10.9141C58.1562 9.22063 56.7794 7.8438 55.0859 6.98094C53.1607 6 50.6405 6 45.6 6H26.4C21.3595 6 18.8393 6 16.9141 6.98094C15.2206 7.8438 13.8438 9.22063 12.9809 10.9141C12 12.8393 12 15.3595 12 20.4V51.6C12 56.6405 12 59.1607 12.9809 61.0859C13.8438 62.7794 15.2206 64.1562 16.9141 65.0191C18.8393 66 21.3595 66 26.4 66H42M42 33H24M30 45H24M48 21H24M49.5 45.0067C50.0286 43.5041 51.0719 42.237 52.4452 41.4299C53.8185 40.6228 55.4331 40.3278 57.003 40.5971C58.573 40.8664 59.997 41.6826 61.0228 42.9012C62.0486 44.1198 62.6101 45.6621 62.6077 47.255C62.6077 51.7517 55.8628 54 55.8628 54M55.9497 63H55.9797"
        stroke={fill ?? 'currentColor'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FileQuestionIcon;
