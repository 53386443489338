import { Avatar, Box, Typography } from '@mui/material';
import { ManagmentButton } from '@/components';
import { ButtonTypeI } from '@/components/buttons/type';
import { MutableRefObject, PropsWithChildren, useRef, useState } from 'react';
import { TWorkspaceBlockModes } from '@/components/manageWorkspaceMenu/type';
import { WORKSPACE_BLOCK_MODES } from '@/components/manageWorkspaceMenu/const';
import { usePostWorkspaceMutation, usePutWorkspaceMutation } from '@/services/api/workspaces';
import { FormikProps } from 'formik';
import { useInvalidateUserInfo } from '@/hooks/useInvalidateUserInfo';
import WorkspaceForm from '@/components/manageWorkspaceMenu/WorkspaceBlock/WorkspaceForm';

type Props = {
  name: string;
  ownerName: string;
  typeButton: ButtonTypeI;
  onButtonClick: () => void;
  onCancelClick?: () => void;
  id?: number;
  isOwner?: boolean;
  initialMode?: TWorkspaceBlockModes;
};

const WorkspaceBlockTemplate = ({
  name,
  typeButton,
  onButtonClick,
  onCancelClick,
  ownerName,
  children,
  id = 0,
  isOwner = false,
  initialMode = WORKSPACE_BLOCK_MODES.VIEW
}: PropsWithChildren<Props>) => {
  const invalidateUserInfo = useInvalidateUserInfo();
  const formikRef = useRef<FormikProps<{ name: string }>>(null);
  const [onUpdate] = usePutWorkspaceMutation();
  const [onCreate] = usePostWorkspaceMutation();
  const [mode, setMode] = useState<TWorkspaceBlockModes>(initialMode);

  const [values, setValues] = useState<{ name: string }>({ name });

  const handleEdit = async () => {
    if (mode === WORKSPACE_BLOCK_MODES.EDIT) {
      formikRef.current?.submitForm();
      return;
    }

    setMode(WORKSPACE_BLOCK_MODES.EDIT);
  };

  const onSubmit = async ({ name }: { name: string }) => {
    if (mode === WORKSPACE_BLOCK_MODES.CREATE) {
      await onCreate({ name });
    }

    if (mode === WORKSPACE_BLOCK_MODES.EDIT) {
      await onUpdate({ name, id });
    }

    await invalidateUserInfo();

    setMode(WORKSPACE_BLOCK_MODES.VIEW);
  };

  const handleClick = async () => {
    if (mode === WORKSPACE_BLOCK_MODES.CREATE) {
      await formikRef.current?.submitForm();
    }

    onButtonClick();
  };

  return (
    <Box
      sx={{
        border: '1px solid #EAECF0',
        padding: '20px',
        borderRadius: '12px',
        backgroundColor: '#EAECF01A',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Box>
        <Avatar
          sx={{
            width: '48px',
            height: '48px',
            color: '#393A3D',
            backgroundColor: '#D4D7DBA3',
            fontWeight: 'bold'
          }}
        >
          {mode === WORKSPACE_BLOCK_MODES.VIEW
            ? name[0]?.toUpperCase()
            : values.name[0]?.toUpperCase()}
        </Avatar>
        {mode === WORKSPACE_BLOCK_MODES.VIEW && (
          <Typography sx={{ marginTop: '10px', fontSize: '20px' }}>{name}</Typography>
        )}
        {(mode === WORKSPACE_BLOCK_MODES.EDIT || mode === WORKSPACE_BLOCK_MODES.CREATE) && (
          <WorkspaceForm
            formikRef={formikRef as unknown as MutableRefObject<FormikProps<{ name: string }>>}
            onSubmit={onSubmit}
            initialValues={{ name }}
            setValues={setValues}
          />
        )}
        <Typography sx={{ fontSize: '12px', color: '#828282' }}>Owner: {ownerName}</Typography>
        {children && children}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <ManagmentButton isFullWidth buttonType={typeButton} onClick={handleClick} />
        {isOwner && (
          <ManagmentButton
            isFullWidth
            buttonType={mode === WORKSPACE_BLOCK_MODES.VIEW ? 'EDIT' : 'SAVE'}
            onClick={handleEdit}
          />
        )}
        {mode === WORKSPACE_BLOCK_MODES.CREATE && (
          <ManagmentButton isFullWidth onClick={onCancelClick ?? (() => {})} buttonType="CANCEL" />
        )}
      </Box>
    </Box>
  );
};

export default WorkspaceBlockTemplate;
