type TPageable = {
  offset: number;
  pageSize: number;
  pageNumber: number;
};

type TGetReportsResponse = {
  totalPages: number;
  pageable: TPageable;
  totalElements: number;
};

type Pagination = {
  totalPages: number;
  offset: number;
  limit: number;
  hasMore: boolean;
  totalResults: number;
};

export const getPagination = (data: TGetReportsResponse | undefined): Pagination => {
  if (!data) {
    return {
      totalPages: 1,
      offset: 0,
      limit: 0,
      hasMore: false,
      totalResults: 0
    };
  }

  return {
    totalPages: data?.totalPages || 1,
    offset: data?.pageable?.offset || 0,
    limit: data?.pageable?.pageSize || 0,
    hasMore:
      ((data?.pageable?.pageNumber || 0) + 1) * (data?.pageable?.pageSize || 0) <
      (data?.totalElements || 0),
    totalResults: data?.totalElements || 0
  };
};
