import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { FC } from 'react';
import { TModalContentProps } from '../modal.types';

const ModalContent: FC<TModalContentProps> = ({ content }) => (
  <DialogContent>
    <DialogContentText>{content}</DialogContentText>
  </DialogContent>
);

export default ModalContent;
