import { BoxProps, Button, ButtonProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const AccountButtonStyled = styled(Button, {
  name: 'AccountButton',
  slot: 'Root'
})<ButtonProps>(() => ({
  backgroundColor: 'transparent',
  padding: '0.6rem 1rem',
  border: '1px solid #DFE1E3',
  textTransform: 'none',
  justifyContent: 'flex-start',
  borderRadius: '.75rem',
  gap: '.5rem',
  '&:hover': {
    backgroundColor: '#F4F5F7',
    border: '1px solid #DFE1E3'
  }
}));

export const AccountButtonAvatarStyled = styled(Box, {
  name: 'AccountButtonAvatar',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const AccountButtonContentStyled = styled(Box, {
  name: 'AccountButtonContent',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left'
}));
