import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Button from '@/components/buttons/Button';

type Props = {
  onClick: () => void;
  label: string;
  icon?: string;
  rootStyle?: SxProps<Theme>;
  typographyStyle?: SxProps<Theme>;
};

const AuthButton = ({ onClick, typographyStyle, rootStyle, label, icon }: Props) => {
  return (
    <Button
      isFullWidth
      title={label}
      onClick={onClick}
      titleStyles={typographyStyle}
      icon={icon && <img src={icon} alt="auth-icon" />}
      styles={{
        border: 'solid 1px #12182620',
        borderRadius: '12px',
        height: '44px',
        ...rootStyle
      }}
    />
  );
};

export default AuthButton;
