import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import PageLoader from '@/components/PageLoader/PageLoader';
import useSession from '@/hooks/useSession';
import { useAuth } from '@/contexts/AuthContext';
import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors';
import { setInitFlow, setSession } from '@/slices';
import { Auth0Session, Auth0User } from '@/types';
import { useGetUserInfoMutation } from '@/services/api/user';
import { usePostWorkspaceMutation } from '@/services/api/workspaces';
import { INVITAION_TOKEN_KEY } from '@/hooks/useInviteTokenHandling';
import { getRedirectUrl } from '@/router/Router/AuthCallback/helpers';

function AuthSuccessRedirect(): React.ReactElement {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSession();
  const handleFormApiErrors = useHandleFormApiErrors();
  const { getUser, getAuthInfo } = useAuth();
  const [getUserInfo] = useGetUserInfoMutation();
  const [createWorkspace] = usePostWorkspaceMutation();

  useEffect(() => {
    const getAuth = async () => {
      try {
        const authResult: Auth0Session = await getAuthInfo();
        if (!authResult) {
          return;
        }
        if (authResult.accessToken) {
          const auth0User: Auth0User = await getUser(authResult.accessToken);
          let userInfo: any = await getUserInfo({ accessToken: authResult.accessToken });

          if (
            userInfo?.data?.data?.workspaces?.length === 0 &&
            !localStorage.getItem(INVITAION_TOKEN_KEY)
          ) {
            await createWorkspace({
              name: auth0User.nickname,
              accessToken: authResult.accessToken
            });
            userInfo = await getUserInfo({ accessToken: authResult.accessToken });
          }

          dispatch(
            setSession({
              ...authResult,
              user: auth0User,
              workspaces: userInfo?.data?.data?.workspaces || [],
              invitations: userInfo?.data?.data?.invitations || [],
              currentWorkspaceId: userInfo?.data?.data?.workspaces?.[0]?.id
            })
          );

          dispatch(
            setInitFlow({ isFirstConnect: true, isRedirectAfterConnectToReportCreate: true })
          );
        }
      } catch (error) {
        console.log({ error });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (error?.errorDescription && error.error !== 'invalid_token') {
          handleFormApiErrors({
            error: {},
            defaultMessage:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              error?.errorDescription ?? 'Something went wrong, please try again.',
            showFieldErrorsAsToast: true
          });
        }
      }
    };

    getAuth();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!user) {
        navigate('/signin');
      }
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [user]);

  if (user) {
    return <Navigate to={getRedirectUrl()} state={{ from: location }} />;
  }

  return <PageLoader />;
}

export default React.memo(AuthSuccessRedirect);
