export enum ESortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export enum ESearchParam {
  SEARCH = 'q',
  PAGE = 'page',
  SORT_ORDER = 'sort_order',
  SORT_KEY = 'sort_key',
  SIZE = 'size'
}
