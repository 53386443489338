import React, { FC } from 'react';
import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import { paths } from '@/utils/paths';

const Error404Page: FC = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Helmet>
        <title>{config.site.name} | Error: Not Found</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          flexGrow: 1,
          py: '80px'
        }}
      >
        <Container maxWidth="lg">
          <Typography align="center" variant={mobileDevice ? 'h4' : 'h2'}>
            404: The page you are looking for isn’t here
          </Typography>
          <Typography align="center" color="textSecondary" sx={{ mt: 0.5 }} variant="subtitle2">
            We can’t seem to find the page you are looking for.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Link to={paths.home}>
              <Button component="a" variant="outlined">
                Go Back Home
              </Button>
            </Link>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default React.memo(Error404Page);
