import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { useAlert } from './contexts/AlertContext';
import { Route as AppRoute } from './types';
import RequireSignIn from './router/Router/RequireSignIn';
import LoginPage from './pages/auth/Login';
import SignupPage from './pages/auth/Signup';
import AuthCallback from './router/Router/AuthCallback';
import Error404Page from './pages/error/Error404';
import Alert from './components/Alert';
import routes from './config/routes';
import ConnectCompanyPage from './pages/ConnectCompany';
import DashboardLayout from '@/components/dashboard/layout';
import TwoFactorAuthPage from '@/pages/auth/TwoFactorAuthentication';
import ForgotPasswordPage from './pages/auth/ForgotPassword';
import InvitationPage from './pages/Invitation';
import SignupStep1Page from './pages/Invitation/SignupStep1';
import SignupStep2Page from './pages/Invitation/SignupStep2';
import SignupStep3Page from './pages/Invitation/SignupStep3';
import SelectUserReportTemplatePage from './pages/CreateNewReportPage/userReport';
import { ConnectionNew } from './pages/dashboard';
import { paths } from '@/utils/paths';
import LicenseAgreement from '@/pages/LicenseAgreement';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import ConnectToQuickBooks from '@/pages/ConnectToQuickBooks';

const PageDefault = () => <div></div>;

function App() {
  const { alert, setAlert } = useAlert();

  return (
    <Router>
      <Routes>
        {routes.map(({ key, component, path, title, isPrivate }: AppRoute) => {
          const Component = component || PageDefault;
          return (
            <Route
              key={key}
              path={path}
              element={
                isPrivate ? (
                  <RequireSignIn>
                    <DashboardLayout title={title}>
                      <Component />
                    </DashboardLayout>
                  </RequireSignIn>
                ) : (
                  <DashboardLayout title={title}>
                    <Component />
                  </DashboardLayout>
                )
              }
            />
          );
        })}
        <Route path="/dashboard/connections/new" element={<ConnectionNew />} />
        <Route path="/user-new-report" element={<SelectUserReportTemplatePage />} />
        <Route path="/auth-callback" element={<AuthCallback />} />
        <Route path={paths.signin} element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/connect-company" element={<ConnectCompanyPage />} />
        <Route path="/two-factor-auth" element={<TwoFactorAuthPage />} />
        <Route path={paths.invitation.workspace} element={<InvitationPage />} />
        <Route path={paths.invitation.signupStep1} element={<SignupStep1Page />} />
        <Route path={paths.invitation.signupStep2} element={<SignupStep2Page />} />
        <Route path={paths.invitation.signupStep3} element={<SignupStep3Page />} />
        <Route path="/license-agreement" element={<LicenseAgreement />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/connect-to-quickbooks" element={<ConnectToQuickBooks />} />
        <Route path="*" element={<Error404Page />} />
      </Routes>
      {alert ? (
        <Alert
          openError={true}
          onClose={() => {
            setAlert(undefined);
          }}
          type={alert.type}
          description={alert.description}
        />
      ) : undefined}
    </Router>
  );
}

export default App;
