import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const SelectReportingMethodStyled = styled(Box, {
  name: 'SelectReportingMethod',
  slot: 'Root'
})<BoxProps>({
  display: 'flex',
  flexDirection: 'column',
  gap: '3rem'
});

export const SelectReportingMethodBodyStyled = styled(Box, {
  name: 'SelectReportingMethodBody',
  slot: 'Root'
})<BoxProps>({});

export const SelectReportingMethodFooterStyled = styled(Box, {
  name: 'SelectReportingMethodFooter',
  slot: 'Root'
})<BoxProps>({});

export const AccountButtonsStyled = styled(Box, {
  name: 'AccountButtons',
  slot: 'Root'
})<BoxProps>(({ theme }) => ({
  display: 'grid',
  // gridTemplateColumns: theme.breakpoints.down('xs') ? '1fr' : 'repeat(2, 1fr)',
  gap: '1rem'
}));
