import React from 'react';
import ReportWrraper from '@/components/report';
import { useNavigate } from 'react-router-dom';
import { EFORMAT_API, ReportFormat } from '@/entity';
import { OnFinishData } from '@/components/report/type';
import { usePutReportTemplateFileMutation } from '@/services/api/reportTemplate';
import { useCreateReport } from '@/hooks/useCreateReport';

function SelectReportTemplatePage(): React.JSX.Element {
  const [uploadReporttemplateFile] = usePutReportTemplateFileMutation();
  const navigate = useNavigate();

  const { onCreate } = useCreateReport();

  const onApply = async (reqBody: OnFinishData, isGoogleSheet: ReportFormat) => {
    const response = await onCreate(reqBody, isGoogleSheet);
    if (!response) {
      console.error('Error: reportingMethod is empty');
      return;
    }

    const file = reqBody?.reportingMethod?.file;
    if (file) {
      const reportTemplateId = response?.reportTemplate?.id;
      await uploadReporttemplateFile({ id: reportTemplateId, file: file });
    }
    navigate('/');
  };

  return (
    <>
      <ReportWrraper
        onApplyGoogleSheets={form => onApply(form, EFORMAT_API.GOOGLE_SHEETS)}
        onApplyExcel={form => onApply(form, EFORMAT_API.EXCEL)}
      />
    </>
  );
}

export default SelectReportTemplatePage;
