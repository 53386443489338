import { Stack } from '@mui/material';
import { CustomDownButton } from '../report/type';
import { OutlinedLightButton, PrimaryButton } from '@/components';
import { ErrorField, TemplateParameterWithValue } from '@/components/parameterValues/type';

interface CustomButtonGroupProps {
  customDownButtons: CustomDownButton[];
  value?: any;
  parameters?: TemplateParameterWithValue[];
  onValidate?: () => ErrorField;
}

const CustomButtonGroup = ({
  customDownButtons,
  value,
  parameters,
  onValidate
}: CustomButtonGroupProps) => {
  return (
    <>
      {!!customDownButtons.length && (
        <Stack direction="row" justifyContent="flex-end" sx={{ gap: '0.75rem' }}>
          {customDownButtons.map((button, index) => {
            const SelectButton = button.isOutline ? OutlinedLightButton : PrimaryButton;

            return (
              <SelectButton
                key={`${index}-${button?.label}-${button?.key}`}
                label={button?.label || 'Apply'}
                onClick={() => {
                  const errorValidation = onValidate && onValidate();

                  if (!Object.keys(errorValidation ?? {}).length) {
                    button?.onClick(value, parameters);
                  }
                }}
                startIcon={button?.startIcon}
                disabled={button?.disabled}
              />
            );
          })}
        </Stack>
      )}
    </>
  );
};

export default CustomButtonGroup;
