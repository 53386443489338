import React from 'react';

function UserEditIcon({ fill, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke={fill ?? 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9 15.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 00-2.667 2.667C2 18.907 2 19.604 2 21M14.5 7.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM11 21l3.101-.886c.149-.043.223-.064.292-.096a.998.998 0 00.175-.102c.061-.045.116-.1.225-.21l6.457-6.456a1.768 1.768 0 10-2.5-2.5l-6.457 6.457c-.11.11-.164.164-.209.225a.998.998 0 00-.102.175 2.07 2.07 0 00-.096.292L11 21z"
      ></path>
    </svg>
  );
}

export default UserEditIcon;
