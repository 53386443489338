import React, { FC, PropsWithChildren } from 'react';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Theme } from '@mui/system';
import { AppCardStyled } from './appCard.styled';

type Props = {
  style?: SxProps<Theme>;
};

const AppCard: FC<PropsWithChildren<Props>> = ({ children, style }) => {
  return <AppCardStyled sx={style}>{children}</AppCardStyled>;
};

export default AppCard;
