import Box from '@mui/material/Box';
import { useMemo } from 'react';
import { ButtonDelete, ButtonJoin, ButtonLeave } from '@/components';
import ButtonEdit from '@/components/buttons/edit';
import Button from '@/components/buttons/Button';
import { ManagmentButtonsProps } from './type';

const ManagmentButton = ({ buttonType = 'DEFAULT', ...rest }: ManagmentButtonsProps) => {
  const RenderButton = useMemo(() => {
    switch (buttonType) {
      case 'DELETE':
        return <ButtonDelete {...rest} />;
      case 'LEAVE':
        return <ButtonLeave {...rest} />;
      case 'JOIN':
        return <ButtonJoin {...rest} />;
      case 'EDIT':
        return <ButtonEdit {...rest} />;
      case 'SAVE':
        return <Button title="Save" {...rest} />;
      case 'CANCEL':
        return <ButtonLeave title="Cancel" {...rest} />;
      default:
        return <Button title={rest.title || 'Title'} {...rest} />;
    }
  }, [buttonType, rest]);

  return <Box>{RenderButton}</Box>;
};

export default ManagmentButton;
