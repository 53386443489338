import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Input, InputAdornment, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import { SearchIcon, SquareIconButton, TColumnItem, ZapOffIcon } from '@/components';
import TrashIcon from '@/components/icons/Trash';
import { ESearchParam, ESortOrder } from '@/utils/enums';
import { ConnectionsPage } from '@/pages/Connections';
import ConnectionTableRow from '@/components/connectionTableRow/ConnectionTableRow';
import Modal from '@/components/modal/Modal';
import ConfirmDelete from '../../components/modals/confirmDelete/ConfirmDelete';
import ConnectionsModal from './ConnectionsModal';
import useGetCompany from '@/hooks/companyService/useCompany';
import useDebouncedValue from '@/hooks/useDebouncedValue/useDebouncedValue';
import { CompanyRowI } from '@/hooks/companyService/type';
import { useDeleteCompanyMutation } from '@/services';
import useSession from '@/hooks/useSession';

const pagination = { totalPages: 50, offset: 2, limit: 10, hasMore: false, totalResults: 30 };

const MOCK_COLUMNS: TColumnItem[] = [
  // { label: 'ID', accessor: 'id', sortable: true, props: { width: 180 } },
  { label: 'Company name', accessor: 'companyName', sortable: false, props: { width: 350 } },
  { label: 'Platform', accessor: 'platform', sortable: true, props: { width: 350 } },
  { label: 'Created at', accessor: 'created', sortable: true, props: { width: 435 } },
  { label: 'Actions', accessor: 'actions', props: { width: 130 } }
];

const defaultOrder = ESortOrder.ASC;
const defaultSortKey = 'id';

interface Props {
  exceptCell?: string[];
  actionsList?: React.ReactNode[];
  columns?: TColumnItem[];
  onTableRowClick?: (connection: CompanyRowI) => void;
}

const ConnectionsPageContainer = ({
  exceptCell = ['id'],
  actionsList,
  columns = MOCK_COLUMNS,
  onTableRowClick
}: Props) => {
  const { currentWorkspaceId } = useSession();
  const [deleteConnectionId, setDeleteConnectionId] = useState<string | number | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<ESortOrder>(defaultOrder);
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const debouncedSearch = useDebouncedValue(searchTerm);
  const { companies, isLoading, refetch } = useGetCompany({
    page: page - 1,
    size: 10,
    sortOrder,
    sortKey,
    search: debouncedSearch || undefined
  });
  const [deleteCompany] = useDeleteCompanyMutation();

  useEffect(() => {
    refetch();
  }, [currentWorkspaceId]);

  useEffect(() => {
    const searchTerm = searchParams.get(ESearchParam.SEARCH);
    const page = searchParams.get(ESearchParam.PAGE);
    const sortOrder = searchParams.get(ESearchParam.SORT_ORDER);
    const sortKey = searchParams.get(ESearchParam.SORT_KEY);

    const isPageMoreThanOne = page && +page > 1;

    const searchValue = searchTerm || '';
    const pageValue = isPageMoreThanOne ? +page : 1;

    searchValue && searchParams.set(ESearchParam.SEARCH, searchValue);
    isPageMoreThanOne && searchParams.set(ESearchParam.PAGE, pageValue.toString());
    sortOrder && searchParams.set(ESearchParam.SORT_ORDER, sortOrder);
    sortKey && searchParams.set(ESearchParam.SORT_KEY, sortKey);
    setSearchParams(searchParams);

    setSearchTerm(searchValue);
    setPage(pageValue);
    setSortOrder((sortOrder || defaultOrder) as ESortOrder);
    setSortKey(sortKey || defaultSortKey);
  }, [searchParams, setSearchParams]);

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchTerm = event.target.value;
      searchTerm
        ? searchParams.set(ESearchParam.SEARCH, searchTerm)
        : searchParams.delete(ESearchParam.SEARCH);

      searchParams.delete(ESearchParam.PAGE);
      setPage(1);
      setSearchParams(searchParams);
      setSearchTerm(searchTerm);
    },
    [searchParams, setSearchParams]
  );

  const handleChangePage = useCallback(
    (newPage: number) => {
      newPage > 1
        ? searchParams.set(ESearchParam.PAGE, newPage.toString())
        : searchParams.delete(ESearchParam.PAGE);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleJumpFirstPage = useCallback(() => {
    searchParams.delete(ESearchParam.PAGE);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleJumpLastPage = useCallback(() => {
    searchParams.set(ESearchParam.PAGE, pagination.totalResults.toString());
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onSortOrderChange = useCallback(
    (sortKey: string, sortOrder: ESortOrder) => {
      if (sortKey === defaultSortKey && sortOrder === defaultOrder) {
        searchParams.delete(ESearchParam.SORT_KEY);
        searchParams.delete(ESearchParam.SORT_ORDER);
        setSearchParams(searchParams);
        return;
      }
      searchParams.set(ESearchParam.SORT_KEY, sortKey);
      searchParams.set(ESearchParam.SORT_ORDER, sortOrder);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onDeleteModalClose = useCallback(async () => {
    if (deleteConnectionId) {
      await deleteCompany({ id: deleteConnectionId });
      refetch();
    }
    setDeleteConnectionId(null);
  }, [deleteConnectionId]);

  const onHandlerTableRowClick = (connection: CompanyRowI) => {
    onTableRowClick && onTableRowClick(connection);
  };

  const rows = useMemo(() => {
    // return reports.map(reportItem => { //TODO It is necessary to rozkomite and return this after the API is up and running.
    return companies.map(connection => {
      return (
        <ConnectionTableRow
          exceptCell={exceptCell}
          key={connection.id}
          item={connection}
          actionsList={
            actionsList || [
              <SquareIconButton
                key="zap"
                onClick={() => {
                  console.log('zap icon click');
                }}
              >
                <ZapOffIcon fill="#B6B7B9" />
              </SquareIconButton>,
              <SquareIconButton
                variant="danger"
                key="delete"
                onClick={() => setDeleteConnectionId(connection.id)}
              >
                <TrashIcon fill="#FF6363" />
              </SquareIconButton>
            ]
          }
          onTableRowClick={() => {
            onHandlerTableRowClick(connection);
          }}
        />
      );
    });
  }, [companies]);

  const isPaginationDisabled = useMemo(() => {
    return pagination.totalPages <= 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies?.length, pagination?.limit]);

  return (
    <>
      <ConnectionsPage
        isLoading={isLoading} //TODO uncomit this when api start work
        // isLoading={false} // TODO remove this when api start work
        isPaginationDisabled={isPaginationDisabled}
        columns={columns}
        rows={rows}
        TableToolbar={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="1rem"
            padding="1.25rem"
          >
            <Typography variant="h5">Xero and QuickBooks Online Connections</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Input
                size="small"
                placeholder="Search..."
                type="search"
                onChange={onSearchChange}
                value={searchTerm}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <ConnectionsModal />
            </Box>
          </Stack>
        }
        onSortOrderChange={onSortOrderChange}
        sortKey={sortKey}
        sortOrder={sortOrder}
        paginationProps={{
          count: pagination.totalResults,
          onPageChange: handleChangePage,
          page,
          onJumpToFirstPage: handleJumpFirstPage,
          onJumpToLastPage: handleJumpLastPage
        }}
        paginate={isPaginationDisabled}
      />
      <Modal open={!!deleteConnectionId} onClose={onDeleteModalClose}>
        <ConfirmDelete
          title="Are you sure you want to delete this?"
          description="This action will cause deletion of all reports related to this connection."
          confirmLabel="Delete"
          cancelLabel="Cancel"
          onConfirm={onDeleteModalClose}
          onCancel={() => setDeleteConnectionId(null)}
        />
      </Modal>
    </>
  );
};

export default ConnectionsPageContainer;
