import * as React from 'react';
import Box from '@mui/material/Box';
// import XeroIcon from '@/components/icons/Xero';
import XeroIcon from '@/assets/images/Xero.png';
import { useNavigate } from 'react-router-dom';

import { Paper, Stack, Typography } from '@mui/material';
import { OutlinedLightButton } from '@/components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import linkIcon from '@/assets/icons/link.svg';
import authSvg from '@/assets/icons/auth.svg';
import AuthButton from '@/components/auth/authButton/AuthButton';
import useSession from '@/hooks/useSession';
import { useDispatch } from 'react-redux';
import { setInitFlow } from '@/slices';
import QuickBooksButton from '@/components/QuickBooksButton';
import { useConnectToQuickBooks } from '@/hooks/useConnectToQuickBooks';

export default function ConnectCompanyPage(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { initFlow } = useSession();
  const onHandlerConnectQuickbook = useConnectToQuickBooks();

  React.useEffect(() => {
    if (initFlow?.isFirstConnect) {
      dispatch(setInitFlow({ isFirstConnect: false, isRedirectAfterConnectToReportCreate: true }));
    }
  }, []);

  return (
    <Box
      sx={{
        display: { xs: 'flex', lg: 'grid' },
        flexDirection: 'column',
        gridTemplateColumns: '1fr 1fr',
        minHeight: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'normal',
          color: 'var(--mui-palette-common-white)',
          display: { xs: 'none', lg: 'flex' },
          justifyContent: 'left',
          height: '100vh',
          width: '100%'
        }}
      >
        <img src={authSvg} style={{ height: '100%' }} alt="authSvg" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Paper
          sx={{
            maxWidth: '474px',
            mx: { xs: 2, sm: 'auto' },
            my: '50px',
            padding: '48px 32px 32px 32px',
            borderRadius: '12px',
            width: '100%'
          }}
          elevation={9}
        >
          <Stack gap="32px" sx={{ textAlign: 'center' }}>
            <Stack sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                style={{
                  width: '72px',
                  height: '72px'
                }}
                src={linkIcon}
                alt="linkIcon"
              />
            </Stack>
            <Stack>
              <Typography
                sx={{ fontSize: { xs: '16px', sm: '24px' }, fontWeight: 700, lineHeight: '30px' }}
                gutterBottom
              >
                Connect your first company
              </Typography>
              <Typography
                justifyContent={'center'}
                sx={{ fontSize: { xs: '14px', sm: '16px' }, color: '#393A3DA3', fontWeight: 400 }}
              >
                Please select a company to continue
              </Typography>
            </Stack>
            <Stack gap="16px">
              <QuickBooksButton onClick={onHandlerConnectQuickbook} />
              <AuthButton
                // onClick={loginWithXero}
                onClick={() => console.log('loginWithXero')}
                label="Connect to Xero"
                icon={XeroIcon}
                typographyStyle={{ color: 'white', fontSize: '14px' }}
                rootStyle={{
                  backgroundColor: '#0B789B',
                  '&:hover': { backgroundColor: '#0078C8' },
                  cursor: 'not-allowed'
                }}
              />
              <OutlinedLightButton
                fullWidth
                label="Back"
                onClick={() => navigate('/')}
                sx={{
                  color: '#747A82'
                }}
                startIcon={
                  <ArrowBackIcon
                    sx={{
                      color: '#747A82'
                    }}
                  />
                }
              />
            </Stack>
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
}
