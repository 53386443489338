import {
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepConnectorProps,
  StepProps,
  styled
} from '@mui/material';

export const BaseStepperConnector = styled(StepConnector)<StepConnectorProps>(() => ({
  left: 'calc(-50% + 50px)',
  right: 'calc(50% + 50px)',
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'repeating-linear-gradient(to right, #1793E5, #1793E5 1.5rem, transparent 20px, transparent 40px)',
      backgroundSize: '2.5rem 2px',
      backgroundColor: 'transparent',
      height: '3px'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'repeating-linear-gradient(to right, #1793E5, #1793E5 1rem, transparent 20px, transparent 40px)',
      backgroundSize: '3rem 2px',
      backgroundColor: 'transparent',
      height: '3px'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    backgroundImage:
      'repeating-linear-gradient(to right, #EAECF0, #EAECF0 1rem, transparent 20px, transparent 40px)',
    backgroundSize: '3rem 2px',
    backgroundColor: 'transparent',
    height: '3px',
    border: 0
  }
}));

export const BaseStepperIconRoot = styled(Box)<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: 'rgba(212, 215, 219, 32%)',
  zIndex: 1,
  color: '#393A3D',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    color: 'rgba(57, 58, 61, 32%)'
  },
  ...(ownerState.active && {
    backgroundColor: '#1793E5',
    color: theme.palette.common.white,
    svg: {
      color: theme.palette.common.white
    }
  }),
  ...(ownerState.completed && {
    backgroundColor: '#1793E5',
    color: theme.palette.common.white,
    svg: {
      color: theme.palette.common.white
    }
  })
}));

export const BaseStepperStep = styled(Step)<StepProps>(() => ({
  // display: 'flex',
  // flexDirection: 'column',
  // '&:first-of-type': {
  //   alignItems: 'start'
  // },
  // '&:last-of-type': {
  //   alignItems: 'end'
  // }
}));
