import { OnFinishData } from '@/components/report/type';
import { EFORMAT_API, ReportFormat } from '@/entity';
import { TReportBody } from '@/services/api/reports/types';
import { formatISO } from 'date-fns';
import { MOCK_CREATE_REPORT_BODY } from '@/utils/mockData/createReport';
import { usePostReportMutation } from '@/services';
import { formationSchedule } from '@/pages/CreateNewReportPage/helpers';
import { SelectReportMethodForm } from '@/components/selectReportingMethod/SelectReportingMethod';
import { ParameterValueApply } from '@/components/parameterValues/type';
import useSession from '../useSession';

export const useCreateReport = () => {
  const [onCreateReport] = usePostReportMutation();
  const { currentWorkspaceId } = useSession();

  const parametersByFormat: Record<
    ReportFormat,
    Pick<TReportBody, 'deliveryFormat' | 'reportFormat'>
  > = {
    [EFORMAT_API.GOOGLE_SHEETS]: {
      deliveryFormat: 'SCHEDULED_EMAIL',
      reportFormat: EFORMAT_API.GOOGLE_SHEETS
    },
    [EFORMAT_API.EXCEL]: {
      deliveryFormat: 'SCHEDULED_SPREADSHEET',
      reportFormat: EFORMAT_API.EXCEL
    }
  };

  const initSchedule = (
    reportingMethod: SelectReportMethodForm,
    parameterValue?: ParameterValueApply
  ) => {
    const schedule = formationSchedule(reportingMethod);
    const startDate = parameterValue?.startDate
      ? parameterValue?.startDate.toString()
      : MOCK_CREATE_REPORT_BODY.startDate;

    const endDate = parameterValue?.endDate
      ? parameterValue?.endDate.toString()
      : MOCK_CREATE_REPORT_BODY.endDate;

    return {
      schedule,
      startDate: formatISO(new Date(startDate)),
      endDate: formatISO(new Date(endDate))
    };
  };

  const onCreate = async (reqBody: OnFinishData, format: ReportFormat) => {
    const { reportingMethod, parameterValue, template } = reqBody;

    if (!reportingMethod) {
      return undefined;
    }

    const { schedule, startDate, endDate } = initSchedule(reportingMethod, parameterValue);

    const prepareBody: TReportBody = {
      ...parametersByFormat[format],
      companyIds: reportingMethod.companyIds,
      emails: [reportingMethod.email || 'test@gmail.com'],
      schedule,
      startDate,
      endDate,
      reportTemplateId: template?.id || MOCK_CREATE_REPORT_BODY.reportTemplateId,
      name: parameterValue?.reportName || MOCK_CREATE_REPORT_BODY.name,
      reportParameters:
        parameterValue?.parameters?.map(({ name, value, dataType, key }) => ({
          name,
          value: value as string,
          dataType,
          key
        })) || MOCK_CREATE_REPORT_BODY.reportParameters
    };

    const response: any = await onCreateReport({ ...prepareBody, workspaceId: currentWorkspaceId });
    return response?.data?.data;
  };

  return { onCreate };
};
