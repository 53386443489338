import { ConnectionsPageContainer } from '@/containers';
import { CompanyRowI } from '@/hooks/companyService/type';

interface Props {
  onClickRow: (connection: CompanyRowI) => void;
}

export const ConnectionForCreateReport = ({ onClickRow }: Props) => {
  return (
    <>
      <ConnectionsPageContainer
        exceptCell={['id', 'actions']}
        actionsList={[]}
        columns={[
          {
            label: 'Company name',
            accessor: 'companyName',
            sortable: false,
            props: { width: 350 }
          },
          { label: 'Platform', accessor: 'platform', sortable: true, props: { width: 350 } },
          { label: 'Created at', accessor: 'created', sortable: true, props: { width: 435 } }
        ]}
        onTableRowClick={onClickRow}
      />
    </>
  );
};
