import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const ParameterValuesStyled = styled(Box, {
  name: 'ParameterValues',
  slot: 'Root'
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '3rem'
}));

export const ParameterValuesBodyStyled = styled(Box, {
  name: 'ParameterValuesBody',
  slot: 'Root'
})(() => ({}));

export const ParameterValuesFooterStyled = styled(Box, {
  name: 'ParameterValuesFooter',
  slot: 'Root'
})(() => ({}));
