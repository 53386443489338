import { SearchInput } from '@/components';

type Props = {
  defaultValueHourOfDay: number;
  onChange: (hour: string) => void;
};

const TimeInput = ({ defaultValueHourOfDay, onChange }: Props) => {
  const formatTime = (hour: number) => {
    const timeFromHourOfDay = new Date(0, 0, 0, hour, 0);
    const hours =
      timeFromHourOfDay.getHours() < 10
        ? `0${timeFromHourOfDay.getHours()}`
        : timeFromHourOfDay.getHours();

    return `${hours}:00`;
  };

  const handleChange = (value: string) => {
    const [hours] = value.split(':');
    onChange(hours);
  };

  return (
    <SearchInput
      type="time"
      inputStyle={{
        xs: '80px',
        sm: '80px',
        '& input': {
          textAlign: 'center'
        },
        'input[type="time"]::-webkit-calendar-picker-indicator': {
          display: 'none'
        }
      }}
      isNotShowSearchIcon
      value={formatTime(defaultValueHourOfDay)}
      onChange={handleChange}
    />
  );
};

export default TimeInput;
