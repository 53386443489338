import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import React, { FC, forwardRef, Ref } from 'react';

import { TBaseDatePickerProps } from './baseDatePicker.types';

const BaseDatePicker: FC<TBaseDatePickerProps> = forwardRef(
  (
    { label, onChange, value, minDate, maxDate, disableFuture, disablePast, errorMessage, name },
    ref: Ref<HTMLInputElement>
  ) => {
    const onHandleChange = (value: Date | null) => {
      onChange && onChange(value);
    };

    return (
      <MobileDatePicker
        minDate={minDate}
        maxDate={maxDate}
        inputRef={ref}
        label={label}
        closeOnSelect={true}
        disableFuture={disableFuture}
        disablePast={disablePast}
        value={value}
        onChange={onHandleChange}
        slotProps={{
          textField: {
            error: !!errorMessage,
            helperText: errorMessage,
            variant: 'standard',
            fullWidth: true,
            name: name ?? ''
          }
        }}
      />
    );
  }
);

BaseDatePicker.displayName = 'BaseDatePicker';

export default BaseDatePicker;
