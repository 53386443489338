import { Box } from '@mui/material';
import { CSSProperties } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  isScroll?: boolean;
  sxContainer?: CSSProperties;
}

export const WorkspaceModalTab = (props: TabPanelProps) => {
  const { children, value, index, isScroll, sxContainer, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            marginTop: '20px',
            maxHeight: '400px',
            ...(sxContainer || {}),
            overflowY: isScroll ? 'scroll' : 'none'
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

export default WorkspaceModalTab;
