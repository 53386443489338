import React from 'react';

function CaretUpDownIcon({ fill, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="16"
      fill="none"
      viewBox="0 0 10 16"
      {...props}
    >
      <path
        stroke={fill ?? 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M.833 10.5L5 14.667 9.166 10.5m-8.333-5L5 1.333 9.166 5.5"
      ></path>
    </svg>
  );
}

export default CaretUpDownIcon;
