import React, { FC } from 'react';
import { SelectButtonStyled } from './selectButton.styled';
import { ButtonProps, Typography } from '@mui/material';

const SelectButton: FC<ButtonProps & { label?: string }> = ({
  children,
  label,
  ...restBtnProps
}) => {
  return (
    <SelectButtonStyled variant="outlined" fullWidth {...restBtnProps}>
      {label ? (
        <Typography
          variant="body2"
          color={restBtnProps?.disabled ? 'rgb(0 0 0 / 26%)' : '#393A3D'}
          fontWeight={600}
        >
          {label}
        </Typography>
      ) : (
        children
      )}
    </SelectButtonStyled>
  );
};

export default SelectButton;
