import { FC, PropsWithChildren } from 'react';

import { Provider as ReduxProvider } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enUS from 'date-fns/locale/en-US';
import { store } from '@/store';
import { AuthContextProvider } from '@/contexts/AuthContext';
import { ThemeProvider } from '../theme';

const MainProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <ReduxProvider store={store}>
        <AuthContextProvider>
          <ThemeProvider>{children}</ThemeProvider>
        </AuthContextProvider>
      </ReduxProvider>
    </LocalizationProvider>
  );
};

export default MainProvider;
