import * as React from 'react';
import { Helmet } from 'react-helmet';

import { config } from '@/config';
import TwoFactorAuthForm from '@/components/auth/TwoFactorAuth/TwoFactorAuthForm';

export default function TwoFactorAuthPage(): React.JSX.Element {
  return (
    <>
      <Helmet>
        <title>TwoFactor | Auth | {config.site.name}</title>
      </Helmet>
      <TwoFactorAuthForm />
    </>
  );
}
