import { accountingReportsApi } from '@/services';
import { AssumeRole, WorkSpaceEndpointIdResponseI, WorkspaceResponse } from '@/types/workspace';
import { EWorspacesPaths } from '@/services/enums';
import { ResponceI } from '@/services/api/base/baseApi';

const workspacesApi = accountingReportsApi.injectEndpoints({
  endpoints: builder => ({
    postWorkspace: builder.mutation<WorkspaceResponse, { name: string; accessToken?: string }>({
      query: ({ name, accessToken }) => {
        const headers = new Headers();

        if (accessToken) {
          headers.set('Access-Control-Allow-Credentials', 'true');
          headers.set('Authorization', `Bearer ${accessToken}`);
        }

        return {
          url: EWorspacesPaths.WORKSPACE,
          method: 'POST',
          headers: headers,
          body: { name }
        };
      }
    }),
    deleteWorkspace: builder.mutation<ResponceI<Record<string, never>>, { id: number }>({
      query: ({ id }) => {
        return {
          url: `${EWorspacesPaths.WORKSPACE}/${id}`,
          method: 'DELETE'
        };
      }
    }),
    getWorkspace: builder.query<WorkSpaceEndpointIdResponseI, number>({
      query: id => {
        return {
          url: `${EWorspacesPaths.WORKSPACE}/${id}`,
          method: 'GET'
        };
      }
    }),
    postAssumeRole: builder.mutation<ResponceI<Record<string, never>>, AssumeRole>({
      query: ({ userId, workspaceId, role }) => {
        return {
          url: EWorspacesPaths.ASSUME_ROLE,
          method: 'POST',
          body: { userId, workspaceId, role }
        };
      }
    }),
    leaveWorkspace: builder.mutation<ResponceI<Record<string, never>>, { id: number }>({
      query: ({ id }) => {
        return {
          url: `${EWorspacesPaths.WORKSPACE}/${id}/leave`,
          method: 'POST'
        };
      }
    }),
    putWorkspace: builder.mutation<WorkspaceResponse, { id: number; name: string }>({
      query: ({ id, name }) => {
        return {
          url: `${EWorspacesPaths.WORKSPACE}/${id}`,
          method: 'PUT',
          body: { name }
        };
      }
    })
  })
});

export const {
  usePostWorkspaceMutation,
  useGetWorkspaceQuery,
  usePostAssumeRoleMutation,
  useDeleteWorkspaceMutation,
  useLeaveWorkspaceMutation,
  usePutWorkspaceMutation
} = workspacesApi;
