import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box, {
  name: 'ContentWrapper',
  slot: 'Root'
})<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows[10],
  overflow: 'hidden',
  borderRadius: '0.75rem',
  border: 'solid 1px #EAECF0',
  gap: '1rem'
}));
