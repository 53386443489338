import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { SelectButton, TIconCardProps } from '@/components';
import { IconCardBodyStyled, IconCardHeaderStyled, IconCardStyled } from './iconCard.styled';

const IconCard: FC<TIconCardProps> = ({ titleIcon, title, description, actions = [] }) => {
  const actionsMarkup = !!actions.length && (
    <IconCardBodyStyled>
      <Stack direction="column" gap="1rem">
        {actions.map(({ icon, label, onClick, disabled }) => (
          <SelectButton
            key={label}
            startIcon={icon}
            label={label}
            onClick={onClick}
            disabled={disabled}
          />
        ))}
      </Stack>
    </IconCardBodyStyled>
  );

  return (
    <IconCardStyled>
      <IconCardHeaderStyled>
        {titleIcon}
        <Stack direction="column" gap="1rem">
          <Typography variant="h5" fontWeight="bold" textAlign="center">
            {title}
          </Typography>
          <Typography color="#393A3D">{description}</Typography>
        </Stack>
      </IconCardHeaderStyled>
      {actionsMarkup}
    </IconCardStyled>
  );
};

export default IconCard;
