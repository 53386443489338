import * as React from 'react';
// import { useRouter } from 'next/navigation';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '@/contexts/AuthContext';
import useSession from '@/hooks/useSession';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { shadows } from '@/styles/theme/shadows';
import { getGreetings } from '@/utils/getGreetings';
import { Stack } from '@mui/material';
import UserCircle from '@/components/icons/UserCircle';
import { paths } from '@/utils/paths';

export type UserPopoverProps = {
  anchorEl: Element | null;
  onClose: () => void;
  open: boolean;
};

export function UserPopover({ anchorEl, onClose, open }: UserPopoverProps): React.JSX.Element {
  const { user } = useSession();
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isAccountPage = location.pathname.includes('account');

  const handleNavigate = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 155, left: 2000 }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      onClose={onClose}
      open={open}
      slotProps={{
        paper: {
          sx: {
            minWidth: '246px',
            borderRadius: '12px',
            padding: '20px 16px 16px 16px',
            boxShadow: shadows[3]
          }
        }
      }}
    >
      <Box sx={{ pb: '19px' }}>
        <Stack direction="row" gap={0.3}>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            {getGreetings()},
          </Typography>
          <Typography variant="subtitle2">{user?.nickname}</Typography>
        </Stack>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        sx={{
          pt: '16px',
          '& .MuiMenuItem-root': { borderRadius: 1 }
        }}
      >
        <MenuItem
          onClick={() => handleNavigate(paths.account.general)}
          sx={{
            color: isAccountPage ? '#0078C8' : '#4B4A4A',
            backgroundColor: isAccountPage ? '#F1F2F3' : 'white',
            mb: 1,
            py: '10px',
            '&:hover': {
              color: '#0078C8',
              '& svg': {
                color: '#0078C8'
              }
            }
          }}
        >
          <ListItemIcon>
            <UserCircle color={isAccountPage ? '#0078C8' : '#4B4A4A'} />
          </ListItemIcon>
          Account
        </MenuItem>
        <MenuItem
          onClick={signOut}
          sx={{
            color: '#4B4A4A',
            py: '10px',
            '&:hover': {
              color: '#0078C8',
              '& svg': {
                fill: '#0078C8'
              }
            }
          }}
        >
          <ListItemIcon>
            <LogoutOutlinedIcon sx={{ color: '#4B4A4A', transition: 'none' }} />
          </ListItemIcon>
          Log out
        </MenuItem>
      </MenuList>
    </Popover>
  );
}
