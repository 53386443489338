export const paths = {
  home: '/',
  auth: {
    signIn: '/signin',
    signUp: '/signup',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password'
  },
  dashboard: {
    home: '/',
    connections: '/dashboard/connections',
    team: '/dashboard/teams',
    support: '/dashboard/support'
  },
  reports: {
    newReport: '/new-report'
  },
  account: {
    general: '/account',
    settings: '/settings',
    plan: '/account/plan'
  },
  invitation: {
    workspace: '/invitation/:token',
    signupStep1: '/invitation/signup/1',
    signupStep2: '/invitation/signup/2',
    signupStep3: '/invitation/signup/3'
  },
  signin: '/signin'
} as const;
