import plusIcon from '@/assets/icons/plus.svg';
import { ManagmentButtonsProps } from './type';
import Button from '@/components/buttons/Button';

const ButtonJoin = ({ onClick, title, ...rest }: ManagmentButtonsProps) => {
  return (
    <Button
      title={title || 'Join'}
      onClick={onClick}
      icon={<img src={plusIcon} alt="plusIcon" />}
      {...rest}
    />
  );
};

export default ButtonJoin;
