import { Route } from '@/types';
import TeamPage from '../pages/Team';
import { paths } from '@/utils/paths';
import CreateNewReportPage from '@/pages/CreateNewReportPage';
import AccountPage from '@/pages/account';
import { ConnectionsPageContainer, HomePageContainer } from '@/containers';

const routes: Array<Route> = [
  {
    key: 'router-home',
    title: 'Home',
    description: 'Home',
    component: HomePageContainer,
    path: '/',
    isEnabled: true,
    appendDivider: true,
    isPrivate: true
  },
  {
    key: 'router-home',
    title: 'Connections',
    description: 'Connections',
    component: ConnectionsPageContainer,
    path: '/dashboard/connections',
    isEnabled: true,
    appendDivider: true,
    isPrivate: true
  },
  {
    key: 'router-team',
    title: 'Team',
    description: 'Team',
    component: TeamPage,
    path: '/dashboard/teams',
    isEnabled: true,
    appendDivider: true,
    isPrivate: true
  },
  {
    key: 'router-new-report',
    title: 'Create new',
    description: 'New Report',
    component: CreateNewReportPage,
    path: paths.reports.newReport,
    isEnabled: true,
    appendDivider: true,
    isPrivate: true
  },
  {
    key: 'account',
    title: 'Account',
    description: 'Account',
    component: AccountPage,
    path: paths.account.general,
    isEnabled: true,
    appendDivider: true,
    isPrivate: true
  }
];

export default routes;
