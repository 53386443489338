import { FC } from 'react';
import TableRow from '@mui/material/TableRow';
import { Avatar, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import { MemberTableRowStyled } from './memberTableRow.styled';
import { MemberTableRowProps } from './memberTableRow.types';

const MemberTableRow: FC<MemberTableRowProps> = ({ item, actionsList, onTableRowClick }) => {
  const { id, ...restMemberData } = item;
  const tableCellsMarkup = Object.entries(restMemberData).map(([key, value]) => {
    const onAction = () => onTableRowClick && onTableRowClick(id);
    return (
      <MemberTableRowStyled key={key} onClick={onAction} sx={{ cursor: 'pointer' }}>
        <Stack direction={'row'} alignItems={'center'}>
          {/* {key === 'name' && <Avatar alt={value} sx={{ mr: 1 }} src="/assets/avatar.jpeg" />} */}
          <Typography variant="subtitle2">{value || '——'}</Typography>
        </Stack>
      </MemberTableRowStyled>
    );
  });

  return (
    <TableRow hover>
      {tableCellsMarkup}
      <MemberTableRowStyled>{actionsList}</MemberTableRowStyled>
    </TableRow>
  );
};

export default MemberTableRow;
