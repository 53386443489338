import React from 'react';

function SheetsIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_121_6026"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="17"
        height="22"
      >
        <path
          d="M4.5 2.5C4.5 1.67157 5.17157 1 6 1H14.5L20.5 7V21.5C20.5 22.3284 19.8284 23 19 23H6C5.17157 23 4.5 22.3284 4.5 21.5V2.5Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_121_6026)">
        <path
          d="M4.5 2.5C4.5 1.67157 5.17157 1 6 1H19C19.8284 1 20.5 1.67157 20.5 2.5V21.5C20.5 22.3284 19.8284 23 19 23H6C5.17157 23 4.5 22.3284 4.5 21.5V2.5Z"
          fill="#20A464"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 23C5.17157 23 4.5 22.3284 4.5 21.5V21.25C4.5 22.0784 5.17157 22.75 6 22.75H19C19.8284 22.75 20.5 22.0784 20.5 21.25V21.5C20.5 22.3284 19.8284 23 19 23H6Z"
          fill="#149456"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 1C5.17157 1 4.5 1.67157 4.5 2.5V2.75C4.5 1.92157 5.17157 1.25 6 1.25H19C19.8284 1.25 20.5 1.92157 20.5 2.75V2.5C20.5 1.67157 19.8284 1 19 1H6Z"
          fill="#38AE74"
        />
        <path
          d="M20.5 12.125L15.1402 6.76517C14.9039 6.52893 15.0712 6.125 15.4053 6.125H20.5V12.125Z"
          fill="url(#paint0_linear_121_6026)"
        />
        <path d="M14.5 1L20.5 7H16C15.1716 7 14.5 6.32843 14.5 5.5V1Z" fill="#8ED1B1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 11.75H15.5H16.5V12.75V14V14.75V16V16.75V18V19H15.5H13H12H9.5H8.5V18V16.75V16V14.75V14V12.75V11.75H9.5H12H13ZM12 12.75H9.5V14H12V12.75ZM13 14V12.75H15.5V14H13ZM12 14.75H9.5V16H12V14.75ZM13 16V14.75H15.5V16H13ZM12 16.75H9.5V18H12V16.75ZM13 18V16.75H15.5V18H13Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_121_6026"
          x1="17.5"
          y1="12.125"
          x2="17.5"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20A464" />
          <stop offset="1" stopColor="#207E55" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SheetsIcon;
