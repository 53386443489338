import { FC } from 'react';
import TableRow from '@mui/material/TableRow';
import { Checkbox, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { TemplateTableRowStyled } from './templateTableRow.styled';
import { TReportTableRowProps } from './templateTableRow.types';

const TemplateTableRow: FC<TReportTableRowProps> = ({
  item,
  actionsList,
  onTableRowClick,
  onClickCheckBox,
  except,
  checked
}) => {
  const { id, ...restReportData } = item;
  const tableCellsMarkup = Object.entries(restReportData)
    .filter(([key, value]) => !except?.some(item => item === key))
    .map(([key, value]) => {
      const onAction = () => onTableRowClick && onTableRowClick(id);
      const onHandlerCheckBoxClick = () => onClickCheckBox && onClickCheckBox(id);
      return (
        <TemplateTableRowStyled key={key} onClick={onAction} sx={{ cursor: 'pointer' }}>
          <Stack direction={'row'} alignItems={'center'}>
            {/* {key === 'name' && (
            <Checkbox
              checked={checked}
              onClick={onHandlerCheckBoxClick} 
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<RadioButtonCheckedIcon />}
              sx={{
                '&.Mui-checked': {
                  color: '#0078C8'
                }
              }}
            />
          )} */}
            <Typography variant="subtitle2">{value || '——'}</Typography>
          </Stack>
        </TemplateTableRowStyled>
      );
    });

  return (
    <TableRow hover>
      {tableCellsMarkup}
      <TemplateTableRowStyled>
        <Stack direction="row" spacing="1rem">
          {actionsList}
        </Stack>
      </TemplateTableRowStyled>
    </TableRow>
  );
};

export default TemplateTableRow;
