import { Stack } from '@mui/material';

// import TeamNameCard from './TeamNameCard';
// import TeamDescriptionCard from './TeamDescriptionCard';
import MembersTable from './MembersTable';

function TeamPage(): React.JSX.Element {
  return (
    <Stack>
      {/* <Stack direction="row" gap={4} height={240}>
        <TeamNameCard />
        <TeamDescriptionCard />
      </Stack> */}
      <MembersTable />
    </Stack>
  );
}

export default TeamPage;
