import { useGetReportsQuery } from '@/services';
import { TGetPageParams } from '@/services/api/reports/types';
import { TReport, TReportDb } from '@/entity';
import { parseISO, differenceInDays, format } from 'date-fns';
import { getPagination } from '@/lib/getPagination';
import { daysOfWeek } from '@/components/selectReportingMethod/selectInterval';
import useSession from '@/hooks/useSession';

const transformData = (reportItem: TReportDb): TReport => {
  const parsedDate1 = parseISO(reportItem.startDate);
  const parsedDate2 = parseISO(reportItem.endDate);
  const diffInDays = differenceInDays(parsedDate2, parsedDate1);

  const period = reportItem?.schedule?.period;
  const hourOfDay = reportItem?.schedule?.hourOfDay;
  const offset = reportItem?.schedule?.offsets;
  let partSchedule = undefined;
  const amOrPm = hourOfDay / 12 > 1 ? `${hourOfDay % 12}pm` : `${hourOfDay}am`;

  switch (period) {
    case 'DAYS':
      partSchedule = `Daily at ${amOrPm}`;
      break;
    case 'MONTHS':
      partSchedule = `Monthly: ${offset.reduce((a, b) => a + ', ' + b, '').slice(1)} at ${amOrPm}`;
      break;
    case 'WEEKS':
      partSchedule = `Weekly: ${daysOfWeek
        .filter(day => offset.find(off => off === day.value))
        .reduce((a, b) => a + ', ' + b.label, '')
        .slice(1)} at ${amOrPm}`;
      break;
    case 'YEARS':
      partSchedule = 'year';
      break;
    default:
      break;
  }
  const companies = reportItem.companies;
  return {
    id: reportItem.id,
    name: reportItem.name,
    companies: companies
      .map((company, index) => `${company.name}${index + 1 !== companies.length ? ',' : ''}`)
      .reduce((a, b) => a + b, ''),
    format: reportItem.reportFormat,
    // companiesRt: companies,
    schedule: reportItem?.schedule?.frequency && partSchedule ? `${partSchedule}` : '',
    createdAt: format(new Date(reportItem.createdAt), 'dd.MM.yyyy'),
    updatedAt: format(new Date(reportItem.lastModifiedAt), 'dd.MM.yyyy')
  };
};

const useGetReports = (params: TGetPageParams) => {
  const { currentWorkspaceId } = useSession();

  const {
    data: body,
    isLoading,
    error,
    refetch
  } = useGetReportsQuery({ ...params, currentWorkspaceId });
  const data = body?.data;

  return {
    isLoading,
    reports: data?.content?.map(transformData) || [],
    reportsWithoutTranform: data?.content || [],
    pagination: getPagination(data),
    error,
    refetch
  };
};

export default useGetReports;
