import { TextField } from '@mui/material';
import { Formik } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { MutableRefObject } from 'react';
import * as Yup from 'yup';

type Props = {
  formikRef: MutableRefObject<FormikProps<{ name: string }>>;
  initialValues: { name: string };
  onSubmit: (values: { name: string }) => void;
  setValues: (values: { name: string }) => void;
};

const WorkspaceForm = ({ formikRef, initialValues, onSubmit, setValues }: Props) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
  });

  return (
    <Formik
      innerRef={formik => {
        formikRef.current = formik as unknown as FormikProps<{ name: string }>;
        return formik ? setValues(formik.values) : setValues({ name: initialValues.name });
      }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <TextField
          name="name"
          variant="standard"
          value={values.name}
          helperText={touched.name && errors.name}
          error={Boolean(errors.name && touched.name)}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter workspace name"
          sx={{
            marginTop: '10px',
            fontSize: '20px'
          }}
        />
      )}
    </Formik>
  );
};

export default WorkspaceForm;
