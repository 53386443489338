import { FC, useState } from 'react';
import { MenuItem, Typography } from '@mui/material';
import * as S from './selectInput.styled';
import Stack from '@mui/material/Stack';
import { ValueOption } from '@/services/api/reportTemplates/types';

type TSelectInput = {
  id: string;
  label: string;
  placeholder: string;
  options: ValueOption[];
  onChange?: (value: string) => void;
  isError?: boolean;
};

const errorStyle = 'rgb(244, 67, 54)';

const SelectInput: FC<TSelectInput> = ({ label, id, placeholder, options, onChange, isError }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event: any) => {
    const targetValue = event.target.value;
    setSelectedOption(targetValue);
    onChange && onChange(targetValue);
  };

  return (
    <Stack direction="column">
      {label && (
        <Typography variant="caption" sx={{ color: isError ? errorStyle : '#393A3DA3' }}>
          {label}
        </Typography>
      )}

      <S.SelectStyled
        id={id}
        value={selectedOption}
        onChange={handleChange}
        displayEmpty
        label={label}
        sx={{
          '& .MuiInputBase-input': {
            ...(isError
              ? {
                  borderBottom: `1px solid ${isError ? errorStyle : 'EAECF0'}`
                }
              : {}),
            borderBottom: `1px solid ${isError ? errorStyle : 'EAECF0'}`
          }
        }}
        inputProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                padding: 0,
                boxShadow: '0px 3px 16px rgba(0, 0, 0, 0.08)'
              }
            }
          }
        }}
      >
        <MenuItem sx={{ display: 'none' }} disabled selected hidden value="">
          {placeholder}
        </MenuItem>
        {options.map(({ name, value }) => (
          <MenuItem key={value} value={value}>
            {name || value}
          </MenuItem>
        ))}
      </S.SelectStyled>
    </Stack>
  );
};

export default SelectInput;
