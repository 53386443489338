import React from 'react';

const EyeIcon = ({ fill, ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke={fill ?? 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.22 12.429c-.114-.18-.17-.27-.202-.408a.977.977 0 010-.373c.032-.138.088-.228.202-.408C5.158 9.755 7.95 6 12.205 6c4.255 0 7.047 3.755 7.985 5.24.114.18.17.27.202.408a.976.976 0 010 .373c-.032.138-.088.228-.202.408-.938 1.485-3.73 5.24-7.985 5.24-4.255 0-7.047-3.755-7.985-5.24z"
      ></path>
      <path
        stroke={fill ?? 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.205 14.335a2.5 2.5 0 100-5.001 2.5 2.5 0 000 5z"
      ></path>
    </svg>
  );
};

export default EyeIcon;
