import React from 'react';

function XeroIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='33'
      height='34'
      fill='none'
      viewBox='0 0 33 34'
      {...props}
    >
      <path
        fill='#fff'
        d='M16.5.5C7.386.5 0 7.888 0 17c0 9.111 7.386 16.5 16.5 16.5C25.61 33.5 33 26.111 33 17 33 7.888 25.61.5 16.5.5z'
      ></path>
      <path
        fill='#13B5EA'
        d='M16.822 13.653a3.785 3.785 0 00-1.997-.56c-.789 0-1.54.23-2.172.668a3.647 3.647 0 00-1.577 2.992c0 .299.037.595.112.88.38 1.449 1.656 2.544 3.177 2.725a3.637 3.637 0 001.343-.09c.397-.092.774-.248 1.123-.463.329-.208.632-.488.95-.88l.022-.02a.603.603 0 00.135-.456.506.506 0 00-.198-.348.587.587 0 00-.348-.121c-.118 0-.291.041-.448.24l-.012.016a2.738 2.738 0 01-.886.77c-.382.199-.794.3-1.224.304-1.353-.015-2.18-.892-2.504-1.707a2.834 2.834 0 01-.11-.405l-.003-.038 5.236-.001c.364-.008.668-.137.858-.364a.897.897 0 00.18-.76c-.222-1.028-.795-1.852-1.657-2.382zM12.246 16.1c.299-1.141 1.35-1.938 2.56-1.938 1.215 0 2.262.795 2.562 1.938h-5.122zm13.637-.3c-.527 0-.956.416-.956.928 0 .513.429.93.956.93a.943.943 0 00.954-.93.943.943 0 00-.954-.929zm-3.428-2.127a.537.537 0 00-.543-.528l-.153-.002a2.18 2.18 0 00-1.274.402.54.54 0 00-.511-.363.529.529 0 00-.54.524l.001 6.081c.002.29.244.526.54.526.297 0 .539-.236.539-.526v-3.74c0-1.212.107-1.718 1.184-1.848.09-.01.186-.011.21-.011.317-.011.547-.228.547-.515zM8.247 16.729l2.794-2.731a.508.508 0 00.159-.37.536.536 0 00-.543-.527.546.546 0 00-.385.157l-2.794 2.718-2.806-2.723a.548.548 0 00-.382-.152.534.534 0 00-.54.527c0 .14.058.275.163.377l2.796 2.722-2.792 2.726a.513.513 0 00-.167.379c0 .29.242.527.54.527a.557.557 0 00.382-.152l2.803-2.73 2.79 2.717a.552.552 0 00.392.166c.299 0 .543-.237.543-.528 0-.14-.056-.271-.159-.372l-2.794-2.73zm17.636-3.646c-2.064 0-3.743 1.635-3.743 3.646 0 2.01 1.679 3.646 3.743 3.646 2.063 0 3.741-1.636 3.741-3.646s-1.678-3.646-3.741-3.646zm0 6.201c-1.447 0-2.624-1.146-2.624-2.555 0-1.41 1.177-2.558 2.624-2.558 1.445 0 2.621 1.148 2.621 2.558s-1.176 2.555-2.621 2.555z'
      ></path>
    </svg>
  );
}

export default XeroIcon;
