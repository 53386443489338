import { accountingReportsApi } from '@/services';
import { ResponceI } from '@/services/api/base/baseApi';
import { TWorkspaceInvitation, WorkspaceInviteRequest } from '@/types/workspace';
import { EInvitationPaths } from '@/services/enums';

const invitationsApi = accountingReportsApi.injectEndpoints({
  endpoints: builder => ({
    getInvitation: builder.query<ResponceI<TWorkspaceInvitation>, { token?: string | null }>({
      query: ({ token }) => ({
        url: EInvitationPaths.INVITATION,
        method: 'GET',
        params: { token }
      })
    }),
    postInvitation: builder.mutation<ResponceI<Record<string, never>>, WorkspaceInviteRequest>({
      query: ({ email, workspaceId, role, expirationPeriod }) => {
        return {
          url: EInvitationPaths.INVITATION,
          method: 'POST',
          body: { email, workspaceId, role, expirationPeriod }
        };
      }
    }),
    postAcceptInvite: builder.mutation<ResponceI<Record<string, never>>, { token: string }>({
      query: ({ token }) => {
        return {
          url: EInvitationPaths.ACCEPT,
          method: 'POST',
          params: { token }
        };
      }
    })
  })
});

export const { useGetInvitationQuery, usePostInvitationMutation, usePostAcceptInviteMutation } =
  invitationsApi;
