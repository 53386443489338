import { FC, PropsWithChildren } from 'react';
import * as S from './contentWrapper.styled';
import { Box, Typography } from '@mui/material';

type TContentWrapper = {
  title?: string;
  isNotBorder?: boolean;
} & PropsWithChildren;

const ContentWrapper: FC<TContentWrapper> = ({ title, children, isNotBorder }) => {
  return (
    <S.Wrapper sx={{
      ...(isNotBorder ? { border: 'none', boxShadow: 'none' } : {})
    }}>
      {title && (
        <Box
          sx={{
            padding: '1.5rem',
            minHeight: '5rem',
            display: 'flex',
            alignItems: 'center',
            borderBottom: 'solid 1px #EAECF0'
          }}
        >
          <Typography variant="h2" sx={{ fontSize: { xs: '20px', sm: '24px' } }}>
            {title}
          </Typography>
        </Box>
      )}
      <Box sx={{ padding: '1.5rem' }}>{children}</Box>
    </S.Wrapper>
  );
};

export default ContentWrapper;
