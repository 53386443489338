import { RootState } from '@/types/store';
import { TWorkespace, TWorkspaceInvitation } from '@/types/workspace';

export const sessionSelectors = {
  session: (state: RootState) => state.session
};

export const getWorkspaces = (state: RootState): TWorkespace[] =>
  sessionSelectors.session(state)?.workspaces ?? [];

export const getInvitations = (state: RootState): TWorkspaceInvitation[] =>
  sessionSelectors.session(state).invitations;
