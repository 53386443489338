import React, { FC, ReactNode, useState } from 'react';
import Menu from '@mui/material/Menu';
import { SquareIconButton } from '@/components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Stack } from '@mui/material';

export type TMoreButtonMenuProps = {
  actions?: ReactNode[];
};

const MoreButtonMenu: FC<TMoreButtonMenuProps> = ({ actions }) => {
  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenuEl(null);
  };
  return (
    <>
      <SquareIconButton onClick={handleOpenMenu}>
        <MoreHorizIcon sx={{ color: '#B2B3B6' }} />
      </SquareIconButton>
      <Menu
        anchorEl={anchorMenuEl}
        open={!!anchorMenuEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Stack p={1} direction="row" gap={1}>
          {actions}
        </Stack>
      </Menu>
    </>
  );
};

export default MoreButtonMenu;
