import { Button, ButtonProps, styled } from '@mui/material';

export const OutlinedLightButtonStyled = styled(Button, {
  name: 'OutlinedLightButton',
  slot: 'Root'
})<ButtonProps>(() => ({
  backgroundColor: 'transparent',
  borderRadius: '.75rem',
  padding: '.56rem 1.75rem',
  border: '1px solid #DFE1E3',
  color: '#747A82',
  fontWeight: 600,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#F4F5F7',
    border: '1px solid #DFE1E3'
  }
}));
