import React, { FC } from 'react';
import { DangerButtonStyled } from './dangerButton.styled';
import { TDangerButtonProps } from './dangerButton.types';

const DangerButton: FC<TDangerButtonProps> = ({ label, children, ...restProps }) => {
  return (
    <DangerButtonStyled variant="outlined" {...restProps}>
      {label || children}
    </DangerButtonStyled>
  );
};

export default DangerButton;
