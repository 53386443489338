import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { FC } from 'react';
import { TModalActionsProps } from '../modal.types';

const ModalActions: FC<TModalActionsProps> = ({ actions = [] }) => {
  if (!actions.length) {
    return null;
  }
  return (
    <DialogActions>
      {actions.map(({ label, ...btnProps }) => (
        <Button key={label} {...btnProps}>
          {label}
        </Button>
      ))}
    </DialogActions>
  );
};

export default ModalActions;
