import { ModalActions, ModalContent, ModalTitle } from './components';

import Dialog from '@mui/material/Dialog';
import { TModalProps } from '@/components/modal/modal.types';
import { FC } from 'react';

const Modal: FC<TModalProps> = ({ title, children, actions, content, ...dialogProps }) => {
  const modalTitleMarkup = title && <ModalTitle title={title} />;
  const modalContentMarkup = content && <ModalContent content={content} />;
  const modalActionsMarkup = actions && <ModalActions actions={actions} />;

  return (
    <Dialog {...dialogProps}>
      {modalTitleMarkup}
      {children}
      {modalContentMarkup}
      {modalActionsMarkup}
    </Dialog>
  );
};

export default Modal;
