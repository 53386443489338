import React, { FC } from 'react';
import { Pagination } from '@mui/material';
import { TCombineTablePaginationProps } from '@/components';
import Stack from '@mui/material/Stack';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { JumpButtonStyled } from './combineTablePagination.styled';

const CombineTablePagination: FC<TCombineTablePaginationProps> = ({
  page,
  onChange,
  count,
  onJumpToLastPage,
  onJumpToFirstPage,
  ...restPaginationProps
}) => {
  return (
    <Stack direction="row" alignItems="center">
      <JumpButtonStyled
        size="small"
        onClick={onJumpToFirstPage}
        disabled={page === 1 || restPaginationProps.disabled}
      >
        <KeyboardDoubleArrowLeftIcon />
      </JumpButtonStyled>
      <Pagination
        onChange={onChange}
        count={count}
        variant="outlined"
        shape="rounded"
        page={page}
        siblingCount={1}
        boundaryCount={0}
        {...restPaginationProps}
      />
      <JumpButtonStyled
        size="small"
        onClick={onJumpToLastPage}
        disabled={page === count || restPaginationProps.disabled}
      >
        <KeyboardDoubleArrowRightIcon />
      </JumpButtonStyled>
    </Stack>
  );
};

export default CombineTablePagination;
