import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box/Box';

export const ReportingMethodWrapper = styled(Box, {
  name: 'ReportingMethodWrapper',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '40.125rem'
}));

export const ReportingMethodStyled = styled(Box, {
  name: 'ReportingMethod',
  slot: 'Root'
})<BoxProps>(() => ({
  maxWidth: '25.625rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem'
}));

export const ReportingMethodBodyStyled = styled(Box, {
  name: 'ReportingMethodBody',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const ReportingMethodFooterStyled = styled(Box, {
  name: 'ReportingMethodFooter',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  alignItems: 'center'
}));
