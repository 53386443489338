import React, { createContext, useContext, useState } from 'react';

import { AlertProps } from '@/components/Alert/types';

type AlertContextType = {
  alert: undefined | Partial<AlertProps>;
  setAlert: React.Dispatch<React.SetStateAction<undefined | Partial<AlertProps>>>;
};

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within a AlertProvider');
  }

  return context;
};

type AlertProviderProps = {
  children: React.ReactNode;
};

const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<undefined | Partial<AlertProps>>(undefined);

  return (
    <AlertContext.Provider
      value={{
        alert,
        setAlert
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
