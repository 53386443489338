import useSession from '@/hooks/useSession';
import { getPagination } from '@/lib/getPagination';
import { useGetReportTemplatesQuery } from '@/services';
import { ReportTemplateContent } from '@/services/api/reportTemplates/types';
import { TGetPageParams } from '@/services/api/reports/types';

export type TReportTemplate = {
  id: number;
  name: string;
  description: string;
  isTemplateFile: boolean;
};

const transformData = (reportTemplateItem: ReportTemplateContent): TReportTemplate => {
  return {
    id: +reportTemplateItem.id,
    name: reportTemplateItem.name,
    description: reportTemplateItem.description,
    isTemplateFile: !!reportTemplateItem.templateFile
  };
};

const useGetReportTemplates = (params: TGetPageParams) => {
  const { currentWorkspaceId } = useSession();

  const {
    data: body,
    isLoading,
    error,
    refetch
  } = useGetReportTemplatesQuery({ ...params, currentWorkspaceId });
  const data = body?.data;

  return {
    isLoading,
    reportTemplates: data?.content?.map(transformData) || [],
    reportTemplatesWithoutTransform: data?.content || [],
    pagination: getPagination(data),
    error,
    refetch
  };
};

export default useGetReportTemplates;
