import { CompanyRowI } from '@/hooks/companyService/type';
import { StyleConfig } from '../report/type';
import { SelectReportMethodForm } from './SelectReportingMethod';

export interface DefaultIntervalValues {
  selectInterval: IntervalI;
  hourOfDay: number;
  dayOfWeek: number[];
  dayOfMonth: number[];
}

export type TSelectReportingMethodProps = {
  userName: string;
  email: string;
  avatarUrl: string;
  onBack?: () => void;
  styleConfig?: StyleConfig;
  onApply?: (value: SelectReportMethodForm) => void;
  company?: CompanyRowI;
  templateId?: number;
  isCreateReport?: boolean;
  defaultInterval?: DefaultIntervalValues;
};

export type IntervalF = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'MANUAL';
export type IntervalB = 'DAYS' | 'WEEKS' | 'MONTHS' | 'YEARS';

export type IntervalI = IntervalF | IntervalB;

export enum INTERVALS {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  MANUAL = 'MANUAL',

  // for request
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}
