import React, { FC } from 'react';
import { NeutralButtonStyled } from './neutralButton.styled';
import { TNeutralButtonProps } from './neutralButton.types';

const NeutralButton: FC<TNeutralButtonProps> = ({ label, children, ...restProps }) => {
  return (
    <NeutralButtonStyled variant="outlined" {...restProps}>
      {label || children}
    </NeutralButtonStyled>
  );
};

export default NeutralButton;
