import { useCallback } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { FormValues } from '@/pages/auth/Signup/types';
import { Button, TextField, Typography } from '@mui/material';
import { AlertProps } from '@/components/Alert/types';
import { HandleFormApiErrors } from '@/hooks/useHandleFormApiErrors';

type Props = {
  setAlert: (alert: undefined | Partial<AlertProps>) => void;
  onSignUp: (email: string, password: string) => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  handleFormApiErrors: HandleFormApiErrors;
  defaultSignUpErrorMessage: string;
  showAlert?: boolean;
};

const SignupForm = ({
  setAlert,
  onSignUp,
  handleFormApiErrors,
  defaultSignUpErrorMessage,
  showAlert = true
}: Props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required')
  });

  const handleSubmit = useCallback(
    async (values: FormValues, { setErrors, resetForm }: FormikHelpers<FormValues>) => {
      try {
        await onSignUp(values.email, values.password);
        if (showAlert) {
          setAlert({
            type: 'success',
            description:
              'A verification email was sent to your email inbox. Please verify your email address.'
          });
        }
        resetForm();
      } catch (error: any) {
        if (
          ['invalid_password', 'invalid_signup', 'extensibility_error', 'user_exists'].includes(
            error.code
          )
        ) {
          handleFormApiErrors({
            defaultMessage:
              error.original?.response?.body?.message ||
              error.original?.response?.body?.description ||
              defaultSignUpErrorMessage,
            error: {},
            setErrors,
            showFieldErrorsAsToast: true
          });
        }
      }
    },
    [handleFormApiErrors, setAlert, onSignUp]
  );

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    initialValues: {
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <TextField
        variant="standard"
        autoFocus
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        label="Email Address"
        margin="normal"
        name="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="email"
        value={formik.values.email}
        InputProps={{ sx: { borderRadius: '12px' } }}
      />
      <TextField
        variant="standard"
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label="Password"
        margin="normal"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
        InputProps={{ sx: { borderRadius: '12px' } }}
      />
      <TextField
        variant="standard"
        error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
        fullWidth
        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        label="Repeat password"
        margin="normal"
        name="confirmPassword"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.confirmPassword}
        InputProps={{ sx: { borderRadius: '12px' } }}
      />
      <Button
        type="submit"
        fullWidth
        disabled={formik.isSubmitting}
        variant="contained"
        sx={{
          my: 2,
          py: '10px',
          textTransform: 'none',
          borderRadius: '12px',
          backgroundColor: '#0078C8',
          '&:hover': { backgroundColor: '#0B789B' }
        }}
      >
        <Typography variant="subtitle2">Continue</Typography>
      </Button>
    </form>
  );
};

export default SignupForm;
