import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import TwoFactorAuthIcon from '../../../assets/images/check-verified.png';

import useSession from '@/hooks/useSession';
import { InputProps } from './types';
import { AuthLayout } from '../Layout';

function TwoFactorAuthForm() {
  const { user } = useSession();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');
  const redirectUri = searchParams.get('redirectUri');
  const [inputValues, setInputValues] = useState(Array(6).fill(''));
  const [focusedIndex, setForcusedIndex] = useState(0);
  const [status, setStatus] = useState('idle');
  const [time, setTime] = useState(112);

  useEffect(() => {
    if (redirectUri) {
      localStorage.setItem('redirectUri', redirectUri);
      if (user) {
        return navigate(redirectUri, { replace: true });
      }
    } else if (user) {
      navigate('/', { replace: true });
    }
  }, [invitationId, redirectUri, user]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(time => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        }
        return time - 1;
      });
    }, 1000);
  }, []);

  const doSubmit = (submittedValues: any) => {
    console.log(`Submitted: ${submittedValues.join('')}`);
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, 1500);
    });
  };

  const clampIndex = (index: number) => {
    if (index > 6) {
      return 6;
    } else if (index < 0) {
      return 0;
    }
    return index;
  };

  const handleInput = (index: number, value: any) => {
    const inputValuesCopy = [...inputValues];
    inputValuesCopy[index] = value;
    setInputValues(inputValuesCopy);
    setForcusedIndex(clampIndex(focusedIndex + 1));
  };

  const handleBack = () => {
    setForcusedIndex(clampIndex(focusedIndex - 1));
  };

  const handlePaste = (pastedValue: string) => {
    setInputValues(inputValues.map((_, index) => pastedValue[index] || ''));

    if (pastedValue.length === 6) {
      setStatus('pending');
      doSubmit(pastedValue.split('')).then(() => setStatus('idle'));
    }
  };

  const handleFocus = (focusedIndex: number) => {
    setForcusedIndex(focusedIndex);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setStatus('pending');
    doSubmit(inputValues).then(() => setStatus('idle'));
  };

  const Input = ({
    index,
    value,
    onChange,
    onPaste,
    onBackspace,
    isFocused,
    onFocus,
    isDisabled
  }: InputProps) => {
    const ref = useRef<HTMLInputElement>(null);
    useEffect(() => {
      requestAnimationFrame(() => {
        if (ref.current && ref.current !== document.activeElement && isFocused) {
          ref.current.focus();
        }
      });
    }, [isFocused]);

    const handleChange = (e: any) => {
      onChange(index, e.target.value);
    };

    const handlePaste = (e: any) => {
      onPaste(e.clipboardData.getData('text'));
    };

    const handleKeyDown = (e: any) => {
      if (e.key === 'Backspace') {
        onBackspace();
      }
    };

    const handleFocus = (e: any) => {
      e.target.setSelectionRange(0, 1);
      onFocus(index);
    };

    return (
      <input
        ref={ref}
        type="text"
        value={value}
        onChange={handleChange}
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
        maxLength={1}
        onFocus={handleFocus}
        disabled={isDisabled}
        placeholder="*"
        style={{
          width: '50px',
          height: '50px',
          border: 'none',
          outline: 'none',
          borderBottom: 'solid 2px #EAECF0',
          fontSize: 40,
          textAlign: 'center',
          alignItems: 'center',
          color: 'black'
        }}
      />
    );
  };
  return (
    <AuthLayout>
      <Box sx={{ p: 4 }}>
        <img
          src={TwoFactorAuthIcon}
          alt="ShowPwd"
          className=" absolute right-4 top-5 cursor-pointer"
        />
      </Box>
      <Typography variant="h4" sx={{ textAlign: 'center' }} gutterBottom>
        Two-factor Authentication
      </Typography>
      <Typography
        variant="h5"
        justifyContent={'center'}
        sx={{ fontSize: { xs: '14px', sm: '16px' } }}
      >
        Please enter the authentication code.
      </Typography>

      <form noValidate onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', gap: 3, p: 4 }}>
          {inputValues.map((value, index) => {
            return (
              <Input
                key={index}
                index={index}
                value={value}
                onChange={handleInput}
                onBackspace={handleBack}
                onPaste={handlePaste}
                isFocused={index === focusedIndex}
                onFocus={handleFocus}
                isDisabled={status === 'pending'}
              />
            );
          })}
        </Box>
        <Typography
          justifyContent={'center'}
          sx={{ fontSize: { xs: '14px', sm: '16px' }, textAlign: 'center' }}
        >
          Resend available in {time} seconds
        </Typography>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={status === 'pending'}
          sx={{
            my: 2,
            p: 2,
            width: '100%',
            textTransform: 'none',
            borderRadius: '12px',
            backgroundColor: '#0078C8',
            '&:hover': { backgroundColor: '#0B789B' }
          }}
        >
          <Typography variant="h6">{status === 'pending' ? 'Verifying' : 'Log In'}</Typography>
        </Button>
      </form>
    </AuthLayout>
  );
}

export default TwoFactorAuthForm;
