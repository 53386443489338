import { useDispatch } from 'react-redux';
import { useGetUserInfoMutation } from '@/services/api/user';
import { setSession } from '@/slices';
import useSession from '@/hooks/useSession';
import { TWorkespace } from '@/types/workspace';

export const useInvalidateUserInfo = () => {
  const dispatch = useDispatch();
  const { currentWorkspaceId } = useSession();
  const [getUserInfo] = useGetUserInfoMutation();

  const updateCurrentWorkspace = (newWorkspaces: TWorkespace[]) => {
    if (!newWorkspaces.find(({ id }) => id === currentWorkspaceId)) {
      dispatch(
        setSession({
          currentWorkspaceId: newWorkspaces[0]?.id
        })
      );
    }
  };

  return async () => {
    try {
      const userInfo: any = await getUserInfo({});
      const workspaces = userInfo?.data?.data?.workspaces || [];
      const invitations = userInfo?.data?.data?.invitations || [];
      dispatch(
        setSession({
          workspaces,
          invitations
        })
      );

      updateCurrentWorkspace(workspaces);
    } catch (error) {
      console.error('Error invalidating user info: ', error);
    }
  };
};
