export const MANAGE_WORKSPACE_TABS = {
  JOINED: 0,
  REQUESTS: 1
} as const;

export const WORKSPACE_BLOCK_MODES = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  CREATE: 'CREATE'
} as const;
