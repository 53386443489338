import { FC, CSSProperties  } from 'react';
import { Typography } from '@mui/material';
import { AttentionIcon, DangerButton, NeutralButton } from '@/components';
import {
  DeleteModalBodyStyled,
  DeleteModalFooterStyled,
  DeleteModalHeaderStyled,
  DeleteModalStyled
} from './confirmDelete.styled';
import { SystemStyleObject, Theme } from '@mui/system';

interface ConfirmStyle {
  container?: CSSProperties;
  stroke?: string;
  dangerButton?: SystemStyleObject<Theme>;
  neutralButton?: SystemStyleObject<Theme>;
}

export type TConfirmDeleteProps = {
  title: string;
  description?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  style?: ConfirmStyle;
};

const ConfirmDelete: FC<TConfirmDeleteProps> = ({
  title,
  description,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  style
}) => {
  return (
    <DeleteModalStyled style={style?.container}>
      <DeleteModalHeaderStyled>
        <AttentionIcon stroke={style?.stroke} />
      </DeleteModalHeaderStyled>
      <DeleteModalBodyStyled>
        <Typography fontWeight="bold" textAlign="center" variant="h4">
          {title}
        </Typography>
        {description && (
          <Typography
            textAlign="center"
            sx={{
              color: '#808183'
            }}
          >
            {description}
          </Typography>
        )}
      </DeleteModalBodyStyled>
      <DeleteModalFooterStyled>
        <DangerButton sx={style?.dangerButton} onClick={onConfirm} fullWidth variant="outlined">
          {confirmLabel}
        </DangerButton>
        <NeutralButton sx={style?.neutralButton} onClick={onCancel} fullWidth variant="outlined">
          {cancelLabel}
        </NeutralButton>
      </DeleteModalFooterStyled>
    </DeleteModalStyled>
  );
};

export default ConfirmDelete;
