import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { TSquareIconButtonProps } from '@/components/ui/squareIconButton/squareIconButton.types';

export const SquareIconButtonStyled = styled(IconButton, {
  name: 'SquareIconButton',
  slot: 'Root'
})<TSquareIconButtonProps>(({ variant }) => ({
  color: 'inherit',
  borderRadius: '.5rem',
  backgroundColor: 'rgba(212, 215, 219, 32%)',
  '&:hover': {
    backgroundColor: '#D4D7DB'
  },
  ...(variant === 'danger' && {
    backgroundColor: '#FFBFBF',
    '&:hover': {
      backgroundColor: '#f8afaf'
    }
  })
}));
