import React from 'react';
import WorkspaceBlockTemplate from '@/components/manageWorkspaceMenu/WorkspaceBlock/WorkspaceBlockTemplate';
import { ButtonTypeI } from '@/components/buttons/type';
import { WorkspaceInviteItem } from '@/components/manageWorkspaceMenu/type';

type Props = {
  workspace: WorkspaceInviteItem;
  typeButton: ButtonTypeI;
  onClick: (buttonType?: string) => void;
};

const InvitedWorkspace = ({ workspace, typeButton, onClick }: Props) => {
  const { owner, name } = workspace;

  return (
    <WorkspaceBlockTemplate
      name={name}
      ownerName={owner}
      typeButton={typeButton}
      onButtonClick={onClick}
    />
  );
};

export default InvitedWorkspace;
