import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { TErrorMessageProps } from './errorMessage.types';

export const ErrorMessageStyled = styled(Box)<Pick<TErrorMessageProps, 'show'>>(({ show }) => ({
  marginTop: show ? '10px' : '0',
  fontSize: '12px',
  color: '#ff8e8f',
  display: 'grid',
  gridTemplateRows: show ? '1fr' : '0fr',
  transition: 'grid-template-rows 0.3s linear',
  overflow: 'hidden',
  '> div': {
    minHeight: 0
  }
}));
