import React from 'react';

function ZapOffIcon({ fill, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={fill ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.66667 6.66675L3.41121 10.5733C3.12054 10.9221 2.9752 11.0965 2.97298 11.2438C2.97105 11.3718 3.02811 11.4937 3.12771 11.5742C3.24229 11.6667 3.46931 11.6667 3.92336 11.6667H10L9.16667 18.3334L13.3333 13.3334M13.0417 8.33342H16.0766C16.5307 8.33342 16.7577 8.33342 16.8723 8.426C16.9719 8.50649 17.0289 8.62832 17.027 8.75636C17.0248 8.90366 16.8795 9.07806 16.5888 9.42687L15.4585 10.7832M8.81063 4.09396L10.8333 1.66675L10.3335 5.66484M17.5 17.5001L2.5 2.50008"
      />
    </svg>
  );
}

export default ZapOffIcon;
