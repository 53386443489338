import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const NoBaseTableResult = styled(Box, {
  name: 'NoBaseTableResult',
  slot: 'Root'
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  maxWidth: '19rem',
  width: '100%'
});

export const NoBaseTableResultIcon = styled(Box, {
  name: 'NoBaseTableResultIcon',
  slot: 'Root'
})({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});
