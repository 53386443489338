import { ButtonProps, styled } from '@mui/material';
import Button from '@mui/material/Button';

export const SelectButtonStyled = styled(Button, {
  name: 'SelectButton',
  slot: 'Root'
})<ButtonProps>(() => ({
  backgroundColor: 'rgba(212, 215, 219, 0.32)',
  padding: '.56rem',
  border: '1px solid #DFE1E3',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#E3F4EC',
    border: '1px solid #DFE1E3'
  }
}));
