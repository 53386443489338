import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useSession from '@/hooks/useSession';
import { AuthLayout } from '@/components/auth/Layout';
import { config } from '@/config';
import SignUpBody from '@/components/auth/SignUpBody';
import { useAuth } from '@/contexts/AuthContext';

export default function SignupPage(): React.JSX.Element {
  const { signUp } = useAuth();
  const { user } = useSession();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');
  const redirectUri = searchParams.get('redirectUri');

  useEffect(() => {
    if (redirectUri) {
      localStorage.setItem('redirectUri', redirectUri);
      if (user) {
        return navigate(redirectUri, { replace: true });
      }
    } else if (user) {
      navigate('/', { replace: true });
    }
  }, [invitationId, redirectUri, user, navigate]);

  return (
    <>
      <Helmet>
        <title>Sign Up | Auth | {config.site.name}</title>
      </Helmet>
      <AuthLayout>
        <SignUpBody onSignUp={signUp} />
      </AuthLayout>
    </>
  );
}
