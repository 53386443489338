import { SelectReportMethodForm } from '@/components/selectReportingMethod/SelectReportingMethod';
import { INTERVALS } from '@/components/selectReportingMethod/type';

export const formationSchedule = (data: SelectReportMethodForm) => {
  let offsets: number[] = [];
  let period = INTERVALS.DAYS;

  switch (data.period) {
    //////
    case INTERVALS.DAILY:
    case INTERVALS.DAYS:
      period = INTERVALS.DAYS;
      offsets = [1];
      break;
    //////
    case INTERVALS.WEEKLY:
    case INTERVALS.WEEKS:
      period = INTERVALS.WEEKS;
      offsets = data.daysOfWeek;
      break;
    //////
    case INTERVALS.MONTHLY:
    case INTERVALS.MONTHS:
      period = INTERVALS.MONTHS;
      offsets = data.daysOfMonth;
      break;
    //////
    default:
      break;
  }

  return {
    frequency: 1,
    period: period,
    offsets: offsets,
    hourOfDay: data.hourOfDay,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };
};
