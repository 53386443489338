import { useGetWorkspaceQuery } from '@/services/api/workspaces';
import { setSession } from '@/slices';
import { paths } from '@/utils/paths';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MAX_DISPLAYED_MEMBERS = 5;

type Props = {
  id: number;
  onNavigate?: () => void;
};

export const useWorkspaceMembers = ({ id, onNavigate }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: workspaceResponse, isLoading, error } = useGetWorkspaceQuery(id);
  const { data: workspaceInfo } = workspaceResponse || {};

  const membersTodisplay = workspaceInfo?.users.slice(0, MAX_DISPLAYED_MEMBERS);
  const membersHidded =
    (workspaceInfo?.users.length ?? MAX_DISPLAYED_MEMBERS) - MAX_DISPLAYED_MEMBERS;

  const showMembersHidded = membersHidded > 0;

  const members = membersTodisplay?.map(({ email }) => email[0].toUpperCase()) ?? [];
  const preparedMembers = showMembersHidded ? members.concat(`+${membersHidded}`) : members;

  const navigateToTeam = () => {
    dispatch(setSession({ currentWorkspaceId: id }));
    navigate(paths.dashboard.team, { replace: true });
    onNavigate && onNavigate();
  };

  return { preparedMembers, isLoading, error, navigateToTeam };
};
