import { styled } from '@mui/material/';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { MenuProps } from '@mui/material/Menu/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import { BoxProps } from '@mui/material/Box/Box';

export const BoxStyled = styled(Box, {
  name: 'ActionsMenu',
  slot: 'Root'
})<BoxProps>(() => ({
  padding: '0'
}));

export const MenuStyled = styled(Menu, {
  name: 'ActionsMenu',
  slot: 'Root'
})<MenuProps>(() => ({
  transform: 'translateX(-20px)',
  marginTop: '5px',

  ul: {
    padding: 0,

    li: {
      button: {
        display: 'flex',
        width: '36px',
        height: '36px'
      }
    }
  }
}));

export const MoreVertIconStyled = styled(MoreVertIcon)({
  transform: 'rotate(90deg)'
});

export const IconButtonStyled = styled(IconButton, {
  name: 'ActionsMenu',
  slot: 'Root'
})<IconButtonProps>(() => ({
  width: '36px',
  height: '36px',
  backgroundColor: 'rgba(212, 215, 219, 32%)',
  borderRadius: '5px',
  '&:hover': {
    backgroundColor: '#D4D7DB'
  }
}));
