import { IconButtonProps, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const JumpButtonStyled = styled(IconButton, {
  name: 'JumpToPrev',
  slot: 'Root'
})<IconButtonProps>(({ theme }) => ({
  color: 'inherit',
  borderRadius: '.375rem',
  padding: '.1875rem',
  backgroundColor: theme.palette.common.white,
  border: '1px solid #EAECF0',
  '&:hover': {
    backgroundColor: '#efefef'
  }
}));
