import * as Yup from 'yup';
import { Box, Button, TextField } from '@mui/material';
import { Formik } from 'formik';

type Props = {
  onSubmit: (values: { email: string }) => void;
};

const InviteMemberForm = ({ onSubmit }: Props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required')
  });

  return (
    <Formik initialValues={{ email: '' }} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            px: 3,
            pt: 4,
            justifyContent: 'space-between'
          }}
        >
          <TextField
            label="Email"
            name="email"
            type="email"
            variant="standard"
            value={values.email}
            helperText={touched.email && errors.email}
            error={Boolean(errors.email && touched.email)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Button
            onClick={handleSubmit as (e: unknown) => void}
            sx={{
              padding: '0rem 2.5rem',
              textTransform: 'none',
              borderRadius: '12px',
              backgroundColor: '#0078C8',
              '&:hover': { backgroundColor: '#0B789B' }
            }}
            variant="contained"
          >
            Invite
          </Button>
        </Box>
      )}
    </Formik>
  );
};

export default InviteMemberForm;
