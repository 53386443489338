import { AppCard, IconCard, OutlinedLightButton, TIconCardProps } from '@/components';
import {
  ReportingMethodBodyStyled,
  ReportingMethodFooterStyled,
  ReportingMethodStyled,
  ReportingMethodWrapper
} from './reportingMethod.styled';
import React, { CSSProperties, FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export type ReportingMethodProps = TIconCardProps & {
  onBackClick?: () => void;
  backBtnLabel?: string;
  styleBlock?: CSSProperties; 
};

const ReportingMethod: FC<ReportingMethodProps> = ({
  onBackClick,
  backBtnLabel = 'Back',
  styleBlock,
  ...restIconCardProps
}) => {
  return (
    <AppCard>
      <ReportingMethodWrapper style={styleBlock}>
        <ReportingMethodStyled>
          <ReportingMethodBodyStyled>
            <IconCard {...restIconCardProps} />
          </ReportingMethodBodyStyled>
          <ReportingMethodFooterStyled>
            <OutlinedLightButton
              fullWidth
              label={backBtnLabel}
              onClick={onBackClick}
              startIcon={
                <ArrowBackIcon
                  sx={{
                    color: '#747A82'
                  }}
                />
              }
            />
          </ReportingMethodFooterStyled>
        </ReportingMethodStyled>
      </ReportingMethodWrapper>
    </AppCard>
  );
};

export default ReportingMethod;
