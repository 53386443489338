import { INVITAION_TOKEN_KEY } from '@/hooks/useInviteTokenHandling';
import { paths } from '@/utils/paths';

export const getRedirectUrl = (): string => {
  const invitationToken = localStorage.getItem(INVITAION_TOKEN_KEY);
  const redirectUri = localStorage.getItem('redirectUri');

  localStorage.removeItem('redirectUri');

  return invitationToken ? paths.invitation.workspace : redirectUri || '/';
};
