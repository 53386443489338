import Typography from '@mui/material/Typography';
import * as S from './noBaseTableResult.styled';
import Box from '@mui/material/Box';
import { FC } from 'react';
import { TNoBaseTableResultProps } from './noBaseTableResult.types';

const NoBaseTableResult: FC<TNoBaseTableResultProps> = ({ title, description }) => (
  <S.NoBaseTableResult>
    <Box>
      <Typography variant="h5" textAlign="center">
        {title}
      </Typography>
      {description && <Typography textAlign="center">{description}</Typography>}
    </Box>
  </S.NoBaseTableResult>
);

export default NoBaseTableResult;
