import { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import AuthButton from '@/components/auth/authButton/AuthButton';
import GoogleIcon from '@/assets/images/Google.png';
import XeroIcon from '@/assets/images/Xero.png';
import { useAuth } from '@/contexts/AuthContext';
import IntuitButton from '@/components/IntuitButton';

type Props = {
  isSignUp?: boolean;
  showQuickBooks?: boolean;
};

const AuthWrapper = ({ children, isSignUp = false }: PropsWithChildren<Props>) => {
  const { loginWithGoogle, loginWithXero, loginWithQuickbooks } = useAuth();

  return (
    <Box>
      {children}
      <Divider sx={{ py: 4 }}>or</Divider>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: { xs: 4, sm: 0, lg: 0 },
          gap: 3,
          flexWrap: 'wrap',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <AuthButton
          onClick={loginWithGoogle}
          label={isSignUp ? 'Sign up with Google' : 'Sign in with Google'}
          icon={GoogleIcon}
          typographyStyle={{ color: 'black' }}
          rootStyle={{
            backgroundColor: '#D4D7DB52',
            '&:hover': { backgroundColor: '#D4D7DB52' }
          }}
        />
        <AuthButton
          onClick={loginWithXero}
          label="Connect to Xero"
          icon={XeroIcon}
          typographyStyle={{ color: 'white' }}
          rootStyle={{
            backgroundColor: '#0B789B',
            '&:hover': { backgroundColor: '#0078C8' }
          }}
        />
        {!isSignUp && <IntuitButton onClick={loginWithQuickbooks} />}
      </Box>
    </Box>
  );
};

export default AuthWrapper;
