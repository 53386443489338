import { Link as MUILink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { paths } from '@/utils/paths';
import AuthWrapper from '@/components/auth/AuthWrapper';
import SignupForm from '@/components/auth/SignupForm';
import { useAlert } from '@/contexts/AlertContext';
import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors';

const defaultSignUpErrorMessage =
  'Something went wrong while creating your account. Please contact support.';

type Props = {
  onSignUp: (email: string, password: string) => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  showAlert?: boolean;
};

const SignUpBody = ({ onSignUp, showAlert = true }: Props) => {
  const { setAlert } = useAlert();
  const handleFormApiErrors = useHandleFormApiErrors();

  return (
    <>
      <Typography variant="h4" sx={{ textAlign: 'center' }} gutterBottom>
        Sign up
      </Typography>
      <Typography justifyContent={'center'} sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
        Already have an account?{' '}
        <Link to={paths.auth.signIn} style={{ boxShadow: 'none' }}>
          <MUILink sx={{ textDecoration: 'none', color: '#0078C8', fontWeight: 600 }}>
            Log in
          </MUILink>
        </Link>
      </Typography>
      <AuthWrapper isSignUp>
        <SignupForm
          showAlert={showAlert}
          onSignUp={onSignUp}
          defaultSignUpErrorMessage={defaultSignUpErrorMessage}
          handleFormApiErrors={handleFormApiErrors}
          setAlert={setAlert}
        />
      </AuthWrapper>
    </>
  );
};

export default SignUpBody;
