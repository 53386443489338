import { Button, ButtonProps, styled } from '@mui/material';

export const NeutralButtonStyled = styled(Button, {
  name: 'NeutralButton',
  slot: 'Root'
})<ButtonProps>(() => ({
  borderRadius: '.75rem',
  padding: '.56rem 1.75rem',
  border: '1px solid #EAECF0',
  textTransform: 'none',
  backgroundColor: '#F1F2F3',
  '&:hover': {
    backgroundColor: '#EBEBEB',
    border: '1px solid #EAECF0'
  }
}));
