import React from 'react';
import ReportWrraper from '@/components/report';
import { Box } from '@mui/material';
import { usePostReportMutation } from '@/services';
import { useNavigate } from 'react-router-dom';
import { EFORMAT_API, ReportFormat } from '@/entity';
import { OnFinishData } from '@/components/report/type';
import { TReportBody } from '@/services/api/reports/types';
import { formatISO } from 'date-fns';
import { SelectReportMethodForm } from '@/components/selectReportingMethod/SelectReportingMethod';
import { INTERVALS } from '@/components/selectReportingMethod/type';
import { usePutReportTemplateFileMutation } from '@/services/api/reportTemplate';
import useSession from '@/hooks/useSession';

const MOCK_CREATE_REPORT_BODY = {
  name: 'Test create 1',
  startDate: '2024-07-04T12:14:58.503Z',
  endDate: '2024-07-04T12:14:58.503Z',
  deliveryFormat: 'SCHEDULED_EMAIL',
  reportParameters: [],
  reportFormat: 'GOOGLE_SHEETS',
  reportTemplateId: 1
};

export const formationSchedule = (data: SelectReportMethodForm) => {
  let offsets: number[] = [];
  let period = INTERVALS.DAYS;

  switch (data.period) {
    //////
    case INTERVALS.DAILY:
      period = INTERVALS.DAYS;
      offsets = [1];
      break;
    case INTERVALS.DAYS:
      period = INTERVALS.DAYS;
      offsets = [1];
      break;
    //////
    case INTERVALS.WEEKLY:
      period = INTERVALS.WEEKS;
      offsets = data.daysOfWeek;
      break;
    case INTERVALS.WEEKS:
      period = INTERVALS.WEEKS;
      offsets = data.daysOfWeek;
      break;
    //////
    case INTERVALS.MONTHLY:
      period = INTERVALS.MONTHS;
      offsets = data.daysOfMonth;
      break;
    case INTERVALS.MONTHS:
      period = INTERVALS.MONTHS;
      offsets = data.daysOfMonth;
      break;
    //////
    default:
      break;
  }

  return {
    frequency: 1,
    period: period,
    offsets: offsets,
    hourOfDay: data.hourOfDay
  };
};

function SelectUserReportTemplatePage(): React.JSX.Element {
  const [onCreateReport] = usePostReportMutation();
  const { currentWorkspaceId } = useSession();
  const [uploadReporttemplateFile] = usePutReportTemplateFileMutation();
  const navigate = useNavigate();

  const onApply = async (reqBody: OnFinishData, isGoogleSheet: ReportFormat) => {
    const reportingMethod = reqBody.reportingMethod;
    if (!reportingMethod) {
      console.log('Error: reportingMethod is empty');
      return;
    }

    const schedule = formationSchedule(reportingMethod);

    const startDate = reqBody?.parameterValue?.startDate
      ? reqBody?.parameterValue?.startDate.toString()
      : MOCK_CREATE_REPORT_BODY.startDate;

    const endDate = reqBody?.parameterValue?.endDate
      ? reqBody?.parameterValue?.endDate.toString()
      : MOCK_CREATE_REPORT_BODY.endDate;

    let prepareBody: TReportBody = {
      ...MOCK_CREATE_REPORT_BODY,
      reportFormat: EFORMAT_API.GOOGLE_SHEETS,
      companyIds: reportingMethod.companyIds,
      emails: [reportingMethod.email || 'test@gmail.com'],
      deliveryFormat: 'SCHEDULED_EMAIL',
      schedule: schedule,
      startDate: formatISO(new Date(startDate)),
      endDate: formatISO(new Date(endDate)),
      reportTemplateId: reqBody?.template?.id || MOCK_CREATE_REPORT_BODY.reportTemplateId,
      name: reqBody?.parameterValue?.reportName || MOCK_CREATE_REPORT_BODY.name
    };

    if (isGoogleSheet === 'EXCEL') {
      prepareBody = {
        ...prepareBody,
        deliveryFormat: 'SCHEDULED_SPREADSHEET',
        reportFormat: EFORMAT_API.EXCEL
      };
    }

    // console.log(prepareBody);

    const response: any = await onCreateReport({ ...prepareBody, workspaceId: currentWorkspaceId });
    const file = reportingMethod.file;
    if (file) {
      const reportTemplateId = response?.data?.data?.reportTemplate?.id;
      await uploadReporttemplateFile({ id: reportTemplateId, file: file });
    }
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          maxWidth: '888px',
          width: '100%',
          marginTop: '64px'
        }}
      >
        <ReportWrraper
          reportStepperStyle={{
            border: 'none',
            boxShadow: 'none'
          }}
          stepsStyle={{
            stepTwo: {
              container: {
                display: 'flex',
                justifyContent: 'center'
              },
              block: {
                width: '474px',
                padding: '48px 32px',
                maxHeight: 'auto',
                minHeight: 'auto'
              }
            }
          }}
          isBackButtonInSecondStep
          onApplyGoogleSheets={form => onApply(form, EFORMAT_API.GOOGLE_SHEETS)}
          onApplyExcel={form => onApply(form, EFORMAT_API.EXCEL)}
        />
      </Box>
    </Box>
  );
}

export default SelectUserReportTemplatePage;
