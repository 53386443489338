import { getSiteURL } from './lib/getSiteURL';

export type Config = {
  site: { name: string; description: string; themeColor: string; url: string };
};

export const config: Config = {
  site: {
    name: 'Clear Sheets',
    description: '',
    themeColor: '#090a0b',
    url: getSiteURL()
  }
};
