import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const TableWithoutResultWrapper = styled(Box, {
  name: 'TableWithoutResultWrapper',
  slot: 'Root'
})({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  width: '100%'
});
