import React from 'react';

function ExelIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
        <rect x="6.08008" y="3.02002" width="17.04" height="19.68" fill="url(#pattern0_121_6052)" />
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
          <path
            d="M21.2897 3.12988H7.77969C7.31025 3.12988 6.92969 3.51044 6.92969 3.97988V20.3099C6.92969 20.7793 7.31025 21.1599 7.77969 21.1599H21.2897C21.7591 21.1599 22.1397 20.7793 22.1397 20.3099V3.97988C22.1397 3.51044 21.7591 3.12988 21.2897 3.12988Z"
            fill="white"
          />
        </g>
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.12">
        <rect x="6.48047" y="2.93994" width="16.08" height="18.72" fill="url(#pattern1_121_6052)" />
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.12">
          <path
            d="M21.2897 3.12988H7.77969C7.31025 3.12988 6.92969 3.51044 6.92969 3.97988V20.3099C6.92969 20.7793 7.31025 21.1599 7.77969 21.1599H21.2897C21.7591 21.1599 22.1397 20.7793 22.1397 20.3099V3.97988C22.1397 3.51044 21.7591 3.12988 21.2897 3.12988Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M14.8108 3.13971H7.81076C7.70008 3.13837 7.59025 3.15918 7.48773 3.20092C7.38521 3.24266 7.29207 3.30448 7.2138 3.38275C7.13553 3.46102 7.07371 3.55415 7.03197 3.65667C6.99023 3.75919 6.96942 3.86903 6.97076 3.97971V7.63971H14.8508L14.8108 3.13971Z"
        fill="#21A366"
      />
      <path
        d="M21.2886 3.13971H14.8086V7.63971H22.1386V3.96971C22.1373 3.8594 22.1143 3.75042 22.0708 3.64901C22.0274 3.54759 21.9644 3.45572 21.8855 3.37865C21.8066 3.30158 21.7132 3.24081 21.6108 3.19981C21.5084 3.15881 21.3989 3.13839 21.2886 3.13971Z"
        fill="#33C481"
      />
      <path d="M22.1286 12.1401H14.8086V16.6501H22.1286V12.1401Z" fill="#107C41" />
      <path
        d="M14.8097 16.6501V12.1401H6.92975V20.3101C6.92841 20.4208 6.94922 20.5307 6.99096 20.6332C7.0327 20.7357 7.09452 20.8288 7.17279 20.9071C7.25106 20.9854 7.34419 21.0472 7.44671 21.0889C7.54923 21.1307 7.65907 21.1515 7.76975 21.1501H21.2897C21.4009 21.1515 21.5112 21.1307 21.6143 21.0891C21.7173 21.0475 21.8111 20.9858 21.8902 20.9077C21.9693 20.8295 22.032 20.7365 22.0749 20.6339C22.1177 20.5313 22.1398 20.4213 22.1397 20.3101V16.6501H14.8097Z"
        fill="#185C37"
      />
      <path d="M14.8197 7.62988H6.92969V12.1399H14.8197V7.62988Z" fill="#107C41" />
      <path d="M22.1286 7.62988H14.8086V12.1399H22.1286V7.62988Z" fill="#21A366" />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.48">
        <rect x="0.5" y="5.83984" width="14.16" height="14.16" fill="url(#pattern2_121_6052)" />
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.48">
          <path
            d="M11.7099 7.06982H3.26992C2.80048 7.06982 2.41992 7.45038 2.41992 7.91982V16.3598C2.41992 16.8293 2.80048 17.2098 3.26992 17.2098H11.7099C12.1794 17.2098 12.5599 16.8293 12.5599 16.3598V7.91982C12.5599 7.45038 12.1794 7.06982 11.7099 7.06982Z"
            fill="white"
          />
        </g>
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.24">
        <rect x="2.16016" y="6.77979" width="10.8" height="10.8" fill="url(#pattern3_121_6052)" />
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.24">
          <path
            d="M11.7099 7.06982H3.26992C2.80048 7.06982 2.41992 7.45038 2.41992 7.91982V16.3598C2.41992 16.8293 2.80048 17.2098 3.26992 17.2098H11.7099C12.1794 17.2098 12.5599 16.8293 12.5599 16.3598V7.91982C12.5599 7.45038 12.1794 7.06982 11.7099 7.06982Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M11.7099 7.06982H3.26992C2.80048 7.06982 2.41992 7.45038 2.41992 7.91982V16.3598C2.41992 16.8293 2.80048 17.2098 3.26992 17.2098H11.7099C12.1794 17.2098 12.5599 16.8293 12.5599 16.3598V7.91982C12.5599 7.45038 12.1794 7.06982 11.7099 7.06982Z"
        fill="#107C41"
      />
      <path
        d="M5.03906 14.8901L6.80906 12.1401L5.18906 9.40014H6.49906L7.37906 11.1401C7.44603 11.2619 7.50289 11.389 7.54906 11.5201C7.60436 11.3864 7.66782 11.2561 7.73906 11.1301L8.73906 9.39014H9.93906L8.22906 12.1401L9.93906 14.9101H8.65906L7.65906 12.9801C7.60582 12.9023 7.56217 12.8184 7.52906 12.7301C7.49582 12.8165 7.45569 12.9001 7.40906 12.9801L6.34906 14.9101L5.03906 14.8901Z"
        fill="white"
      />
      <g style={{ mixBlendMode: 'soft-light' }} opacity="0.5">
        <path
          style={{ mixBlendMode: 'soft-light' }}
          opacity="0.5"
          d="M11.7099 7.06982H3.26992C2.80048 7.06982 2.41992 7.45038 2.41992 7.91982V16.3598C2.41992 16.8293 2.80048 17.2098 3.26992 17.2098H11.7099C12.1794 17.2098 12.5599 16.8293 12.5599 16.3598V7.91982C12.5599 7.45038 12.1794 7.06982 11.7099 7.06982Z"
          fill="url(#paint0_linear_121_6052)"
        />
      </g>
      <defs>
        <pattern
          id="pattern0_121_6052"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_121_6052" transform="scale(0.0138889 0.0120482)" />
        </pattern>
        <pattern
          id="pattern1_121_6052"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image1_121_6052" transform="scale(0.0147059 0.0126582)" />
        </pattern>
        <pattern
          id="pattern2_121_6052"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image2_121_6052" transform="scale(0.0166667)" />
        </pattern>
        <pattern
          id="pattern3_121_6052"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image3_121_6052" transform="scale(0.0217391)" />
        </pattern>
        <linearGradient
          id="paint0_linear_121_6052"
          x1="4.17992"
          y1="6.40982"
          x2="10.7999"
          y2="17.8698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopOpacity="0.7" />
        </linearGradient>
        <image
          id="image0_121_6052"
          width="72"
          height="83"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABTCAYAAAA8/EEfAAAACXBIWXMAAC4jAAAuIwF4pT92AAAB4ElEQVR4Xu3cwWrUUABG4XPbUMGNCnYhOM/hpjtfXRDtYziioFRcNqZeF/dmJh3aHnCZ/GeTdiab+5HJ8i+1VpaVUgobrJ5C9Mr8eYcZgAvgHNgKVAXugBGYTqFKrXXGeQZcAm+BF2wDacb5DeyBH8DtEmlYXC+Bd8AVsKM9SVtoBL4AH4BPwHfgz/zl0J+eC9qTcwW8B95wxFt7E/Ct//0VuCmlHH5qM8I57We1o+G8BM7YRn/7dcfx1XJoBlq+oAcaztrfP3Nn3D/7vXNv5Sn57wIkBUgKkBQgKUBSgKQASQGSAiQFSAqQFCApQFKApABJAZICJAVICpAUIClAUoCkAEkBkgIkBUgKkBQgKUBSgKQASQGSAiQFSAqQFCApQFKApABJAZICJAVICpAUIClAUoCkAEkBkgIkBUgKkBQgKUBSgKQASQGSAiQFSAqQFCApQFKApABJAZICJAVICpA0A1XaXN7Yrw/Odq60J88+r+At1yh/As9py5xrX8KrwC3tzHuawd3yhqHWWkspY7/hI/Cqf/ea9Y9NTjScz7Sz74HxoanSibZjet3//0Vb5lz7XOnywbimGUzLG07XgLc0eKtDt7AAgk1OJj85lQwnQIcPNza6/djYNjwClI79A/7Gf1JmIOGZAAAAAElFTkSuQmCC"
        />
        <image
          id="image1_121_6052"
          width="68"
          height="79"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABPCAYAAABS1GNxAAAACXBIWXMAAC4jAAAuIwF4pT92AAABY0lEQVR4Xu3cvW3bYAAG4aNggEuo8xLp0nmhdB7AnQdy5zITuIo6DSFWXwqK/jkkQWrxHoCFBEoAD59UvtMYg3y4+/ximqYZOALzn2+/SQtwHmMsANN2Qq4xvgGPrFH24gw8AT/HGAtjDK5R7oEX4AKMHV0X4BX4DsxbjBl4AH79xxfc4nVhPQz3B1ZH4Af7+ql89v7fefAbf/3IThzIFwWRgkhBpCBSECmIFEQKIgWRgkhBpCBSECmIFEQKIgWRgkhBpCBSECmIFEQKIgWRgkhBpCBSECmIFEQKIgWRgkhBpCBSECmIFEQKIgWRgkhBpCBSECmIFEQKIgWRgkhBpCBSECmIFEQKIgWRgkhBpCBSECmIFEQKIgWRgsgWZGHd81r+ce8t+3j+ZrsYrM/9AMx3AGOMZZqmE/DGai+LVdvJeANOY4zF039H1sW7vWyanYFn4MR1D/E9yGZnA5FfhiEBfgO20QT8sY3jjAAAAABJRU5ErkJggg=="
        />
        <image
          id="image2_121_6052"
          width="60"
          height="60"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAC4jAAAuIwF4pT92AAADoUlEQVRoQ+2b61LbMBBGj3IhoUAphbbT93+6Ti8DoZCEXNwfq43WshyHUizi+pvZ8SWO2ONd2WH82RVFwf+kUdMBKTnnXNMxr63iLyvl9n3PgFnA7LBGhV0echJqgT2sAwZ+add3hyW++tqykBpbu70PPAnsnBsgcANgGIU9AbmANTaJ2ALbOujKHDawI2AMnJgY+dBjoF1ohdgicGtgBTyZWAE455LQJWDfxgo7AabAO+AMOPX7TqhWui3ZFl4jgHPg0YTz+5PQO+Bozo4RwAvg0sd7BH7qPx/S3ny2SWt1Vwjsb2AG3CI56fE2dopbWqt7gsBdAp+AG+Cj3z6lXOU2pQBrYAk8AHfATwJLaT4755ytsgXWCg+R1j0DPiDAX4HPwBVwTpjPOVpa23kJ3AO/kHwx+5dIW6/jAVIVHiJAp0gbXwNfEOhrpM0nhJbOAbwBFkgrT/1nWvF7v9QO1FsWUAXWCo+Rgc4Q6CukrW/ID6wtvSBUdoG09jlSqNQ1BvDA5oKlFy2tsl6lz31c8DaAN0h+IJW9INxJdLol80v9lrbQ9l48iSI3MMg8nRJyGpOAtReuVEvr0oLbGJn1NmFVOh9tLiOzrj+KkrnVVTgVdiAbOVSXlwVN5nfofTT+Yi5QVV1RiNYragLODfbP1QR8bGosUNeAG9UDd109cNfVA3ddPXDX1QN3XT1w19UDd109cNfVA3ddPXDX1QMfuWrdO6om4MYBjk1NwKoYPPeJiD0c1stR8XVY1VkP4wHsvvgPtK04hy3hKb992p/MMQauGyxlAIP2nyBqXprD2ix13Z6AilIVtoOq8UvNX0vCk/ccz4dtbgsTamRZEcB3Fa64eIqiKLyPVKuqsEs/4IMPNZA8kRdYPR4zH2pkmRPcOyVola2wbeMVAvvoB7tF/BMgf0h9WjmAYxfPD8S6dIeY1OZI/gpcUmoOq8tNYa0Pao6YR9QlA+1CK3Ds0/qGmNNmSN5PhPm8t8J69rS6d5TdMrcE66G1F7Ql29KxE+87kp8FPqjCatp8RAbAb88QC9OE4KhVvTa4TXyf1zKucMU7vQP2F664pUFOgFZbYXNYlqB8nalz0y6Q/JO3ppJB3BjU1I1nPVq6bWFzAEOzX3pDjUm84oiPbMSxL2tAmLttw6oKE6kfRHsd8XWvAMR2oEG0jVm2rcIsbYvvtp/9zsPuw+pbLbkg62ThX/ZWS53ewjtLcBhcSs8GPnYd+v9wZ/QHTbqvfA0XAmsAAAAASUVORK5CYII="
        />
        <image
          id="image3_121_6052"
          width="46"
          height="46"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAwUlEQVRoQ+3ZsQkCMRyF8S8iZAm765zAzr0cwJ3sLG8BN8gQSfW3CIdeZ/VM4P0gTSDwcXDVSxFBSikDJyAzpgaUiGjbRaLHXoAbPX5EBbgD63f8AjyACsSgpwJP4ArkiADgDLx+ePzvU+kfeIkIDsxj9x/OFL7jcDWHqzlczeFqDldzuJrD1Ryu5nA1h6s5XM3hag5Xc7iaw9UcruZwNYcLNPre2aCH7y4G1YCVPtIWgCOf1RYmWpbTtFv+Ni/P5g1xTodLg4W2uAAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
}

export default ExelIcon;
