import { ManagmentButtonsProps } from './type';
import Button from '@/components/buttons/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const ButtonDelete = ({ onClick, title, ...rest }: ManagmentButtonsProps) => {
  return (
    <Button
      title={title || 'Delete'}
      onClick={onClick}
      backgroundColor="#FFDADA"
      color="#FF6363"
      styles={{ border: '1px solid #FFBFBF' }}
      icon={
        <DeleteOutlineOutlinedIcon
          sx={{
            color: '#FF6363'
          }}
        />
      }
      {...rest}
    />
  );
};

export default ButtonDelete;
