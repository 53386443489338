import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box/Box';

export const IconCardStyled = styled(Box, {
  name: 'IconCard',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '3rem'
}));

export const IconCardHeaderStyled = styled(Box, {
  name: 'IconCardHeader',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  alignItems: 'center'
}));

export const IconCardBodyStyled = styled(Box, {
  name: 'IconCardBody',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column'
}));
