import React from 'react';

function UsersIcon({ fill, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke={fill ?? 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18 15.837c1.456.731 2.704 1.905 3.615 3.373.18.29.27.436.302.637.063.409-.216.912-.597 1.073-.188.08-.398.08-.82.08M16 11.532a4.5 4.5 0 000-8.064M14 7.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM2.56 18.938C4.152 16.545 6.67 15 9.5 15s5.346 1.545 6.94 3.938c.35.525.524.787.504 1.122-.015.26-.186.58-.395.738-.267.202-.635.202-1.371.202H3.822c-.736 0-1.104 0-1.372-.202a1.109 1.109 0 01-.395-.738c-.02-.335.155-.597.504-1.122z"
      ></path>
    </svg>
  );
}

export default UsersIcon;
