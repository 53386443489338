import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Link as MUILink, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { config } from '../../../config';
import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors';
import { useAuth } from '@/contexts/AuthContext/AuthContextProvider';
import { useAlert } from '@/contexts/AlertContext';
import useSession from '@/hooks/useSession';
import { paths } from '@/utils/paths';
import { AuthLayout } from '@/components/auth/Layout';
import { FormValues } from './types';

export default function ForgotPasswordPage(): JSX.Element {
  const { user } = useSession();
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const { setAlert } = useAlert();
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');
  const redirectUri = searchParams.get('redirectUri');

  useEffect(() => {
    if (redirectUri) {
      localStorage.setItem('redirectUri', redirectUri);
      if (user) {
        return navigate(redirectUri, { replace: true });
      }
    } else if (user) {
      navigate('/', { replace: true });
    }
  }, [invitationId, redirectUri, user, navigate]);

  const handleFormApiErrors = useHandleFormApiErrors();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required')
  });

  const handleSubmit = useCallback(async (values: FormValues, { setErrors }: any) => {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    try {
      await resetPassword(values.email);
      setAlert({
        description: 'We\'ve just sent you an email to reset your password.',
        type: 'success'
      });
    } catch (error: any) {
      // eslint-disable-line @typescript-eslint/no-explicit-any
      console.log('error: ', error);
      handleFormApiErrors({
        defaultMessage: error.error_description,
        error: {},
        setErrors,
        showFieldErrorsAsToast: true
      });
    }
  }, [handleFormApiErrors, resetPassword, setAlert]);

  const formik = useFormik({
    validateOnBlur: false,
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  return (
    <>
      <Helmet>
        <title>Forgot Password | Auth | {config.site.name}</title>
      </Helmet>
      <AuthLayout>
        <Typography variant="h4" sx={{ textAlign: 'center', width: '100%', pb: 0 }} gutterBottom>
          Forgot Password
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: { xs: '14px', sm: '16px' }, py: 2 }}>
          Enter your email address and we'll send you a link to reset your password
        </Typography>
        <form noValidate onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <TextField
            variant="standard"
            autoFocus
            error={Boolean(formik.touched.email && formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="email"
            value={formik.values.email}
            InputProps={{ sx: { borderRadius: '12px' } }}
          />

          <Button
            type="submit"
            fullWidth
            disabled={formik.isSubmitting}
            variant="contained"
            sx={{
              my: 2,
              p: 2,
              textTransform: 'none',
              borderRadius: '12px',
              backgroundColor: '#0078C8',
              '&:hover': { backgroundColor: '#0B789B' }
            }}
          >
            <Typography variant="h6">Send</Typography>
          </Button>
          <Typography sx={{ textAlign: 'center', fontSize: { xs: '14px', sm: '16px' } }}>
            Remember your password?{' '}
            <Link to={paths.auth.signIn} style={{ boxShadow: 'none' }}>
              <MUILink sx={{ textDecoration: 'none', color: '#0078C8', fontWeight: 600 }}>
                Log in
              </MUILink>
            </Link>
          </Typography>
        </form>
      </AuthLayout>
    </>
  );
}
