import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const AppCardStyled = styled(Box, {
  name: 'AppCardStyled',
  slot: 'Root'
})<BoxProps>(({ theme }) => ({
  boxShadow: theme.shadows[10],
  overflow: 'hidden',
  borderRadius: '0.75rem',
  border: 'solid 1px #EAECF0'
}));
