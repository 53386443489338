import { styled } from '@mui/material/';
import Select from '@mui/material/Select';
import { SelectProps } from '@mui/material/Select/Select';

export const SelectStyled = styled(Select, {
  name: 'SelectInput',
  slot: 'Root'
})<SelectProps>(() => ({
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
    borderRadius: '0',
    '&:hover': {
      backgroundColor: 'red'
    }
  },

  '& .MuiInputBase-input': {
    borderRadius: '0',
    borderBottom: '1px solid #EAECF0'
  }
}));
