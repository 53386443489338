import React, { useState } from 'react';
import { Box, Stack, Tab, Tabs } from '@mui/material';

import CustomTabPanel from '@/components/CustomTabPanel';
import { a11yProps } from '@/utils/a11yProps';
import General from './General';
import Plan from './Plan';

function AccountPage() {
  const [selectedTab, setSelectedValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '15rem' }}>
        <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="GENERAL" {...a11yProps(0)} sx={{ flexGrow: 1 }} />
          <Tab label="PLAN" {...a11yProps(1)} sx={{ flexGrow: 1 }} />
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedTab} index={0}>
        <Stack gap={2}>
          <General />
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <Stack gap={2}>
          <Plan />
        </Stack>
      </CustomTabPanel>
    </Box>
  );
}

export default AccountPage;
