import { CssVarsThemeOptions } from '@mui/material/styles/experimental_extendTheme';
import { paginationItemClasses } from '@mui/material';

const componentsOverrides: CssVarsThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: {
      'body, html': {
        height: '100%',
        width: '100%',
        letterSpacing: 'normal',
        textTransform: 'none',
        a: {
          textDecoration: 'none',
          color: 'inherit',
          display: 'inline-block',
          '&:hover': {
            textDecoration: 'none',
            color: 'inherit'
          }
        }
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        '&:not(:last-of-type)': {
          borderRight: '1px solid #EAECF0'
        },
        borderTop: '1px solid #EAECF0'
      }
    }
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        minHeight: '30rem',
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '.375rem',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #EAECF0',
        [`&.${paginationItemClasses.selected}`]: {
          backgroundColor: '#0078C8',
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: '#0B789B'
          }
        }
      })
    }
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: 'rgba(18, 24, 38, 0.08)'
      }
    }
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:before': {
          borderBottomColor: 'rgba(234, 236, 240, 1)',
        }
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        borderBottom: '3px solid #0078C8'
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: '#0078C8'
        },
        fontSize: '14px',
        fontWeight: 600,
      }
    }
  }
};

export default componentsOverrides;
