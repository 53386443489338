import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { accountingReportsApi } from '@/services';
import { sessionReducer } from '@/slices';

export const rootReducer = combineReducers({
  session: sessionReducer,
  [accountingReportsApi.reducerPath]: accountingReportsApi.reducer
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['session']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE]
      }
    }).concat(accountingReportsApi.middleware)
});

export default store;

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
