import { useGetQuickbooksLoginMutation } from '@/services/api/quickbook/quickbook';
import useSession from '@/hooks/useSession';

export const useConnectToQuickBooks = () => {
  const [getQuickbookPath] = useGetQuickbooksLoginMutation();
  const { currentWorkspaceId } = useSession();

  return async () => {
    const quickBookPath: any = await getQuickbookPath(currentWorkspaceId);
    if (quickBookPath?.data) {
      window.location.href = quickBookPath?.data?.data;
    }
  };
};
