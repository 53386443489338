import HomeIcon from '../../icons/Home';
import AttachmentIcon from '../../icons/Attachment';
import UsersIcon from '../../icons/Users';
import SupportIcon from '../../icons/Support';
import SettingsUserIcon from '../../icons/SettingsUser';

export const navIcons = {
  home: HomeIcon,
  attachments: AttachmentIcon,
  users: UsersIcon,
  support: SupportIcon,
  settingsUser: SettingsUserIcon
} as Record<string, any>;
