import { styled } from '@mui/material';

export const Label = styled('div')`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  color: #1d1a27;
`;

export const Attention = styled('span')<{ hasError: boolean }>`
  font-size: 18px;
  color: ${({ hasError }) => (hasError ? '#ff8e8f' : 'inherit')};
  margin-left: 5px;
`;
