import { styled } from '@mui/material/';
import TableCell from '@mui/material/TableCell';
import { TableRowOwnProps } from '@mui/material/TableRow/TableRow';

export const ReportTableRowStyled = styled(TableCell, {
  name: 'ReportTableRow',
  slot: 'Root'
})<TableRowOwnProps>(() => ({
  padding: '1.25rem 1rem',
  '&:not(:last-of-type)': {
    borderRight: '1px solid #EAECF0'
  }
}));
