import React, { FC } from 'react';
import { PrimaryButtonStyled } from './primaryButton.styled';
import { TPrimaryButtonProps } from './primaryButton.types';

const PrimaryButton: FC<TPrimaryButtonProps> = ({ label, children, ...restProps }) => {
  return (
    <PrimaryButtonStyled variant="contained" color="primary" {...restProps}>
      {label || children}
    </PrimaryButtonStyled>
  );
};

export default PrimaryButton;
