import { Button, ButtonProps, styled } from '@mui/material';

export type TActiveButtonStyledProps = ButtonProps & {
  active?: boolean;
};

export const ActiveButtonStyled = styled(Button, {
  name: 'ActiveButton',
  slot: 'Root'
})<TActiveButtonStyledProps>(({ active }) => ({
  backgroundColor: active ? '#0078C829' : '#EAECF0',
  color: active ? '#0078C8' : '#393A3D',
  fontSize: '0.85rem',
  fontWeight: '600',
  border: 'transparent',
  textTransform: 'none',
  '&:hover, &:focus': {
    border: 'transparent',
    backgroundColor: '#E1EEF7'
  }
}));
