import { ManageTeamBlockProps } from '@/components/manageWorkspaceMenu/type';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import WorkspaceBlockTemplate from '@/components/manageWorkspaceMenu/WorkspaceBlock/WorkspaceBlockTemplate';
import { useWorkspaceMembers } from '@/components/manageWorkspaceMenu/WorkspaceBlock/hooks/useWorkspaceMembers';

const DISPLACEMENT_TO_LEFT_CATEGORIES = 6;

const JoinedWorkspace = ({
  workspace,
  typeButton,
  onClick,
  onModalClose
}: ManageTeamBlockProps) => {
  const { id, isOwner } = workspace;

  const { preparedMembers, isLoading, error, navigateToTeam } = useWorkspaceMembers({
    id,
    onNavigate: onModalClose
  });

  const preparedWorkspace = {
    ...workspace,
    members: preparedMembers
  };

  const { name, owner, members } = preparedWorkspace;

  return (
    <WorkspaceBlockTemplate
      name={name}
      ownerName={owner}
      typeButton={typeButton}
      onButtonClick={onClick}
      isOwner={isOwner}
      id={id}
    >
      <Box
        sx={{ display: 'flex', marginTop: '10px', position: 'relative', cursor: 'pointer' }}
        onClick={navigateToTeam}
      >
        {isLoading && <CircularProgress size={28} />}
        {!isLoading &&
          !error &&
          members.map((member, index) => (
            <Box
              key={`${member}-${index}`}
              sx={{
                border: '1px solid #1218260A',
                borderRadius: '50%',
                color: '#393A3DA3',
                fontSize: '14px',
                padding: '10px',
                width: '28px',
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#FFFFFF',
                position: 'relative',
                left: index ? `-${DISPLACEMENT_TO_LEFT_CATEGORIES * index}px` : '0px'
              }}
            >
              {member}
            </Box>
          ))}
      </Box>
    </WorkspaceBlockTemplate>
  );
};

export default JoinedWorkspace;
