import zapOff from '@/assets/icons/zap-off-gray.svg';
import { ManagmentButtonsProps } from './type';
import Button from '@/components/buttons/Button';

const ButtonLeave = ({ onClick, title, ...rest }: ManagmentButtonsProps) => {
  return (
    <Button
      title={title || 'Leave'}
      onClick={onClick}
      backgroundColor="#D4D7DB52"
      color="#393A3D"
      styles={{ border: '1px solid #EAECF0' }}
      icon={<img src={zapOff} alt="zapOff" />}
      {...rest}
    />
  );
};

export default ButtonLeave;
