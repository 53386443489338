import React, { FC } from 'react';
import { Step, StepIconProps, StepLabel, Stepper, Typography } from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';
import { BaseStepperConnector, BaseStepperIconRoot } from './baseStepper.styled';
import { TStepItem } from '@/components';

const BaseStepperIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <BaseStepperIconRoot ownerState={{ completed, active }} className={className}>
      {props.icon}
    </BaseStepperIconRoot>
  );
};

export type TBaseStepperProps = {
  steps?: TStepItem[];
  activeStep: number;
};

const BaseStepper: FC<TBaseStepperProps> = ({ steps = [], activeStep }) => {
  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<BaseStepperConnector />}>
      {steps.map(({ label, icon = <SettingsIcon /> }) => (
        <Step key={label}>
          <StepLabel StepIconComponent={props => <BaseStepperIcon {...props} icon={icon} />}>
            <Typography>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default BaseStepper;
