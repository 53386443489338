import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Input, InputAdornment, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import { SearchIcon } from '@/components/icons';
import { CombinedTable, SquareIconButton, TColumnItem } from '@/components';
import DownloadIcon from '@/components/icons/Download';
import { EReportTableRowActions } from '@/components/reportTableRow/reportTableRow.types';
import { ESearchParam, ESortOrder } from '@/utils/enums';
import { rowData } from './rowData';
import { TemplateTableRow } from './templateTableRow';
import { StyleConfig } from '@/components/report/type';
import { useGetReportTemplates } from '@/hooks';
import useDebouncedValue from '@/hooks/useDebouncedValue/useDebouncedValue';
import { ReportTemplate, ReportTemplateContent } from '@/services/api/reportTemplates/types';
import useGetCompany from '@/hooks/companyService/useCompany';
import { useGetReportDownloadTemplateQuery } from '@/services/api/reportTemplate';
import { ReportDownloadI } from '@/services/api/reportTemplate/types';
import { CompanyRowI } from '@/hooks/companyService/type';

const columns: TColumnItem[] = [
  { label: 'Name', accessor: 'name', sortable: true },
  { label: 'Description', accessor: 'description', sortable: true },
  { label: 'Actions', accessor: '' }
];

const defaultOrder = ESortOrder.ASC;
const defaultSortKey = 'name';

const getReportItemActions = (
  itemId: number,
  onActionClick?: (itemId: number, action: EReportTableRowActions) => void
) => [
  <SquareIconButton
    key={`${itemId}-${EReportTableRowActions.DOWNLOAD}`}
    onClick={() => onActionClick && onActionClick(itemId, EReportTableRowActions.DOWNLOAD)}
  >
    <DownloadIcon />
  </SquareIconButton>
];

const getFilteredReportItemActions = (
  reportItemId: number,
  onActionClick: (itemId: number, action: EReportTableRowActions) => void,
  option: EReportTableRowActions,
  isIncludes = true
) =>
  getReportItemActions(reportItemId, onActionClick).filter(({ key }) =>
    isIncludes ? key?.includes(option) : !key?.includes(option)
  );

interface ReportTemplatesProps {
  tableStyle?: CSSProperties;
  styleConfig?: StyleConfig;
  onSelectTemplate?: (template: ReportTemplateContent) => void;
  connection?: CompanyRowI;
}

function ReportTemplates({
  tableStyle,
  styleConfig,
  onSelectTemplate,
  connection
}: ReportTemplatesProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<ESortOrder>(defaultOrder);
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [selectReportId, setSelectReportId] = useState<number | undefined>();
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebouncedValue(searchTerm);

  const { reportTemplates, reportTemplatesWithoutTransform, pagination, isLoading } =
    useGetReportTemplates({
      page: page - 1,
      size: 10,
      sortOrder,
      sortKey,
      search: debouncedSearch
    });

  const [downloadParams, setDownloadParams] = useState<ReportDownloadI | undefined>();
  const { data: reportTemplateBlob } = useGetReportDownloadTemplateQuery(downloadParams!, {
    skip: !downloadParams
  });

  useEffect(() => {
    if (reportTemplateBlob) {
      const url = reportTemplateBlob;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${downloadParams?.name || 'reportTemplate'}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setDownloadParams(undefined);
    }
  }, [reportTemplateBlob]);

  useEffect(() => {
    const searchTerm = searchParams.get(ESearchParam.SEARCH);
    const page = searchParams.get(ESearchParam.PAGE);
    const sortOrder = searchParams.get(ESearchParam.SORT_ORDER);
    const sortKey = searchParams.get(ESearchParam.SORT_KEY);

    const isPageMoreThanOne = page && +page > 1;

    const searchValue = searchTerm || '';
    const pageValue = isPageMoreThanOne ? +page : 1;

    searchValue && searchParams.set(ESearchParam.SEARCH, searchValue);
    isPageMoreThanOne && searchParams.set(ESearchParam.PAGE, pageValue.toString());
    sortOrder && searchParams.set(ESearchParam.SORT_ORDER, sortOrder);
    sortKey && searchParams.set(ESearchParam.SORT_KEY, sortKey);
    setSearchParams(searchParams);

    setSearchTerm(searchValue);
    setPage(pageValue);
    setSortOrder((sortOrder || defaultOrder) as ESortOrder);
    setSortKey(sortKey || defaultSortKey);
  }, [searchParams, setSearchParams]);

  const handleChangePage = useCallback(
    (newPage: number) => {
      newPage > 1
        ? searchParams.set(ESearchParam.PAGE, newPage.toString())
        : searchParams.delete(ESearchParam.PAGE);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleJumpFirstPage = useCallback(() => {
    searchParams.delete(ESearchParam.PAGE);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleJumpLastPage = useCallback(() => {
    searchParams.set(ESearchParam.PAGE, pagination.totalResults.toString());
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchTerm = event.target.value;
      searchTerm
        ? searchParams.set(ESearchParam.SEARCH, searchTerm)
        : searchParams.delete(ESearchParam.SEARCH);

      searchParams.delete(ESearchParam.PAGE);
      setSearchParams(searchParams);
      setSearchTerm(searchTerm);
    },
    [searchParams, setSearchParams]
  );

  const onSortOrderChange = useCallback(
    (sortKey: string, sortOrder: ESortOrder) => {
      if (sortKey === defaultSortKey && sortOrder === defaultOrder) {
        searchParams.delete(ESearchParam.SORT_KEY);
        searchParams.delete(ESearchParam.SORT_ORDER);
        setSearchParams(searchParams);
        return;
      }
      searchParams.set(ESearchParam.SORT_KEY, sortKey);
      searchParams.set(ESearchParam.SORT_ORDER, sortOrder);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onActionClick = (itemId: number, action: EReportTableRowActions) => {
    if (action === EReportTableRowActions.DOWNLOAD && connection) {
      const company = connection;
      const platform = company.platform;
      const companyId = company.id;
      const findNameFile = reportTemplates.find(template => template.id === itemId);
      setDownloadParams({
        companyId,
        platform,
        reportTemplateId: itemId,
        name: findNameFile?.name || 'reportTemplate',
        downloadId: Math.floor(Math.random() * (99999 - 1)) + 1
      });
    }
  };

  const onSelectReport = (id: number) => {
    setSelectReportId(id);
    const findTemplate = reportTemplatesWithoutTransform.find(template => template.id === id);
    if (onSelectTemplate && findTemplate) {
      onSelectTemplate(findTemplate);
    }
  };

  const rows = useMemo(() => {
    return reportTemplates.map(reportItem => {
      return (
        <TemplateTableRow
          except={['isTemplateFile']}
          key={reportItem.id}
          item={reportItem}
          checked={reportItem.id === selectReportId}
          onClickCheckBox={onSelectReport}
          actionsList={
            reportItem.isTemplateFile
              ? getFilteredReportItemActions(
                  reportItem.id,
                  onActionClick,
                  EReportTableRowActions.DOWNLOAD
                )
              : []
          }
          onTableRowClick={onSelectReport}
        />
      );
    });
  }, [onActionClick, reportTemplates, selectReportId]);

  const isPaginationDisabled = useMemo(() => {
    return pagination.totalPages <= 1;
  }, [reportTemplates?.length, pagination?.limit]);

  return (
    <Box sx={{ mt: styleConfig?.isNotMarginTop ? 0 : 5 }}>
      <CombinedTable
        isLoading={isLoading}
        isPaginationDisabled={false}
        columns={columns}
        rows={rows}
        TableToolbar={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="1rem"
            padding="1.25rem"
          >
            <Typography variant="h5">Select Report Template</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Input
                size="small"
                placeholder="Search..."
                type="search"
                onChange={onSearchChange}
                value={searchTerm}
                sx={{ borderBottom: '1px solid #EAECF0' }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
          </Stack>
        }
        onSortOrderChange={onSortOrderChange}
        paginationProps={{
          count: pagination.totalPages,
          onPageChange: handleChangePage,
          page,
          onJumpToFirstPage: handleJumpFirstPage,
          onJumpToLastPage: handleJumpLastPage
        }}
        sortKey={sortKey}
        sortOrder={sortOrder}
        paginate={!isPaginationDisabled}
        sx={{
          maxHeight: 550,
          overflow: 'auto',
          display: 'block',
          ...(tableStyle || {})
        }}
      />
    </Box>
  );
}

export default ReportTemplates;
