import useSession from '@/hooks/useSession';
import { usePostQuickbookConnectMutation } from '@/services/api/quickbook/quickbook';
import { setInitFlow } from '@/slices';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ConnectionNew = () => {
  const [searchParams] = useSearchParams();
  const [connectionQuickBook] = usePostQuickbookConnectMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { initFlow, currentWorkspaceId } = useSession();

  const connectQuickBooks = async (code: string, state: string, realmId: string) => {
    await connectionQuickBook({ code, state, realmId, workspaceId: currentWorkspaceId });
    console.log('responce: ', code);
    console.log('responce: ', state);
    console.log('responce: ', realmId);
    if (initFlow?.isRedirectAfterConnectToReportCreate) {
      dispatch(setInitFlow(undefined));
      navigate('/user-new-report');
      return;
    }
    navigate('/dashboard/connections');
  };

  useEffect(() => {
    const searchCode = searchParams.get('code') || '';
    const searchState = searchParams.get('state') || '';
    const searchRealmId = searchParams.get('realmId') || '';

    connectQuickBooks(searchCode, searchState, searchRealmId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default ConnectionNew;
