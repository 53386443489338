export const PARAMETER_UI_ELEMENTS = {
  SWITCH: 'SWITCH',
  DROPDOWN: 'DROPDOWN',
  CHECKBOX: 'CHECKBOX',
  INPUT: 'INPUT'
};

export const PARAMETER_DATA_TYPES = {
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  INTEGER: 'INTEGER',
  DATE: 'DATE',
  DOUBLE: 'DOUBLE'
};
