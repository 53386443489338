import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInvalidateUserInfo } from '@/hooks/useInvalidateUserInfo';
import { useGetInvitationQuery, usePostAcceptInviteMutation } from '@/services/api/invitations';
import { useAlert } from '@/contexts/AlertContext';

export const INVITAION_TOKEN_KEY = 'invitationToken';

type Props = {
  useLocalStorage?: boolean;
};

export const useInviteTokenHandling = (props?: Props) => {
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const [onAcceptInvite] = usePostAcceptInviteMutation();
  const invalidateUserInfo = useInvalidateUserInfo();

  const { useLocalStorage = false } = props || {};

  const { token } = useParams<{ token: string }>();
  const localStorageToken = localStorage.getItem(INVITAION_TOKEN_KEY);

  const invitationToken = useLocalStorage ? localStorageToken : token;

  const { data: invitationResponse } = useGetInvitationQuery(
    { token: invitationToken },
    { skip: !invitationToken }
  );
  const { data } = invitationResponse || {};

  const onAcceptError = () => {
    setAlert({ type: 'error', description: 'Error occured while accepting invitation' });
  };

  const handleAcceptInvite = async (options: { redirectUrl: string }) => {
    const { redirectUrl } = options;
    const localStorageToken = localStorage.getItem(INVITAION_TOKEN_KEY);
    if (!localStorageToken) {
      onAcceptError();
      return;
    }

    try {
      await onAcceptInvite({ token: localStorageToken });
      await invalidateUserInfo();
      localStorage.removeItem(INVITAION_TOKEN_KEY);
      navigate(redirectUrl);
    } catch (error) {
      onAcceptError();
    }
  };

  useEffect(() => {
    if (token && !localStorageToken) {
      localStorage.setItem(INVITAION_TOKEN_KEY, token);
    }
  }, [token, localStorageToken]);

  return { invitationDatails: data, handleAcceptInvite };
};
