import { NavItemConfig } from '../../../types/nav';
import { paths } from '../../../utils/paths';

export const navItems = [
  { key: 'home', title: 'Home', href: paths.dashboard.home, icon: 'home' },
  {
    key: 'connections',
    title: 'Connections',
    href: paths.dashboard.connections,
    icon: 'attachments'
  },
  { key: 'team', title: 'Team', href: paths.dashboard.team, icon: 'users' }
] satisfies NavItemConfig[];

export const navBottomItems = [
  { key: 'support', title: 'Support', href: paths.dashboard.support, icon: 'support' },
  { key: 'settings', title: 'Settings', href: paths.account.settings, icon: 'settingsUser' }
] satisfies NavItemConfig[];
