import * as React from 'react';
import { useMemo } from 'react';
import { NavItemConfig } from '@/types/nav';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Avatar } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { navBottomItems, navItems } from './config';
import { isNavItemActive } from '@/lib/isNavItemActive';
import { Logo } from '../../core/logo';
import { paths } from '@/utils/paths';
import { navIcons } from './navIcons';
import CaretUpDownIcon from '../../icons/CaretUpDown';
import { ManageWorkspaceMenu, ManageWorkspaceModal } from '@/components/manageWorkspaceMenu';
import { ManageWorksSpaceItemI, WorkSpaceItemIdI } from '@/components/manageWorkspaceMenu/type';
import { AvatarStyle } from '@/components/avatar';
import { MOCK_MANAGE_WORKSPACE_ITEM, MOCK_MENU_ITEM_ID } from './mock';
import { useDispatch, useSelector } from 'react-redux';
import { getInvitations, getWorkspaces, setCurrentWorkspaceId } from '@/slices';
import useSession from '@/hooks/useSession';
import { formatRole } from '@/utils/formatting';

export function SideNav(): React.JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;

  const workspaces = useSelector(getWorkspaces);
  const invitations = useSelector(getInvitations);
  const { currentWorkspaceId } = useSession();

  const workspacesItems: ManageWorksSpaceItemI[] = useMemo(
    () => [
      ...workspaces.map(({ name, id, permissions, role }) => ({
        name,
        id,
        isChoose: currentWorkspaceId === id,
        letter: name[0].toUpperCase(),
        subTitle: formatRole(role)
      })),
      MOCK_MANAGE_WORKSPACE_ITEM
    ],
    [workspaces, currentWorkspaceId]
  );
  const currentWorkspaceItem = workspacesItems.find(({ id }) => id === currentWorkspaceId);

  const [isOpenMenu, setIsOpenMenu] = React.useState<boolean>(false);
  const [isOpenModalWorkspace, setIsOpenModalWorkSpace] = React.useState<boolean>(false);

  const onHandlerClickMenuManageWorksSpace = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const onHandlerClickWorkSpaceItem = (id?: WorkSpaceItemIdI) => {
    if (id === MOCK_MENU_ITEM_ID) {
      setIsOpenModalWorkSpace(true);
      return;
    }

    dispatch(setCurrentWorkspaceId(id as number));
  };

  return (
    <Box
      sx={{
        '--SideNav-background': 'var(--mui-palette-neutral-950)',
        '--SideNav-color': 'var(--mui-palette-common-white)',
        '--NavItem-color': 'var(--mui-palette-neutral-300)',
        '--NavItem-hover-background': 'rgba(255, 255, 255, 0.04)',
        '--NavItem-active-background': 'var(--mui-palette-primary-main)',
        '--NavItem-active-color': 'var(--mui-palette-primary-contrastText)',
        '--NavItem-disabled-color': 'var(--mui-palette-neutral-500)',
        '--NavItem-icon-color': 'var(--mui-palette-neutral-400)',
        '--NavItem-icon-active-color': 'var(--mui-palette-primary-contrastText)',
        '--NavItem-icon-disabled-color': 'var(--mui-palette-neutral-600)',
        bgcolor: 'var(--SideNav-background)',
        color: 'var(--SideNav-color)',
        display: { xs: 'none', lg: 'flex' },
        flexDirection: 'column',
        height: '100%',
        left: 0,
        maxWidth: '100%',
        position: 'fixed',
        scrollbarWidth: 'none',
        top: 0,
        width: 'var(--SideNav-width)',
        zIndex: 'var(--SideNav-zIndex)',
        '&::-webkit-scrollbar': { display: 'none' }
      }}
    >
      <Stack spacing={2} sx={{ p: 3 }}>
        <Box component={RouterLink} to={paths.home} sx={{ display: 'inline-flex' }}>
          <Logo color="dark" height={32} width={122} />
        </Box>
        <Box component="nav" sx={{ flex: '1 1 auto', p: 0, marginTop: '48px !important' }}>
          {renderNavItems({ pathname, items: navItems })}
        </Box>
        <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: '#D0D0D01A',
            // backgroundColor: 'var(--mui-palette-neutral-950)',
            // border: '1px solid var(--mui-palette-neutral-700)',
            borderRadius: '12px',
            cursor: 'pointer',
            display: 'flex',
            p: '4px 12px'
          }}
          onClick={onHandlerClickMenuManageWorksSpace}
        >
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              alignItems: 'center',
              gap: 1,
              py: 1
            }}
          >
            <Avatar sx={{ ...AvatarStyle }}>{currentWorkspaceItem?.letter}</Avatar>
            {/* <Avatar sx={{ background: 'var(--mui-palette-primary-700)' }}>E</Avatar> */}
            <Typography color="inherit" variant="subtitle1">
              {currentWorkspaceItem?.name}
            </Typography>
          </Box>
          <CaretUpDownIcon />
        </Box>
        <Box
          sx={{
            display: isOpenMenu ? 'block' : 'none',
            justifyContent: 'space-between'
          }}
        >
          <ManageWorkspaceMenu items={workspacesItems} onClick={onHandlerClickWorkSpaceItem} />
        </Box>
      </Stack>
      <Stack spacing={2} sx={{ p: 3, height: '100%', justifyContent: 'flex-end' }}>
        {renderNavItems({ pathname, items: navBottomItems })}
      </Stack>

      <ManageWorkspaceModal
        isOpen={isOpenModalWorkspace}
        setIsOpen={setIsOpenModalWorkSpace}
        workspaces={workspaces}
        invitations={invitations}
      />
    </Box>
  );
}

function renderNavItems({
  items = [],
  pathname
}: {
  items?: NavItemConfig[];
  pathname: string;
}): React.JSX.Element {
  const children = items.reduce(
    (acc: React.ReactNode[], curr: NavItemConfig): React.ReactNode[] => {
      const { key, ...item } = curr;

      acc.push(<NavItem key={key} pathname={pathname} {...item} />);

      return acc;
    },
    []
  );

  return (
    <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
      {children}
    </Stack>
  );
}

type NavItemProps = {
  pathname: string;
} & Omit<NavItemConfig, 'items'>;

function NavItem({
  disabled,
  external,
  href,
  icon,
  matcher,
  pathname,
  title
}: NavItemProps): React.JSX.Element {
  const active = isNavItemActive({
    disabled,
    external,
    href,
    matcher,
    pathname
  });

  const Icon = icon ? navIcons[icon] : null;

  return (
    <li>
      <Box
        {...(href
          ? {
              component: external ? 'a' : RouterLink,
              to: href,
              target: external ? '_blank' : undefined,
              rel: external ? 'noreferrer' : undefined
            }
          : { role: 'button' })}
        sx={{
          alignItems: 'center',
          borderRadius: 1,
          color: 'var(--NavItem-color)',
          cursor: 'pointer',
          display: 'flex',
          flex: '0 0 auto',
          gap: '12px',
          p: '6px 16px',
          position: 'relative',
          textDecoration: 'none',
          whiteSpace: 'nowrap',
          ...(disabled && {
            bgcolor: 'var(--NavItem-disabled-background)',
            color: 'var(--NavItem-disabled-color)',
            cursor: 'not-allowed'
          }),
          ...(active && {
            bgcolor: '#D0D0D03D',
            // bgcolor: 'var(--NavItem-active-background)',
            color: 'var(--NavItem-active-color)'
          })
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flex: '0 0 auto'
          }}
        >
          {Icon ? (
            <Icon
              fill={active ? 'var(--NavItem-icon-active-color)' : 'var(--NavItem-icon-color)'}
              fontSize="var(--icon-fontSize-md)"
              weight={active ? 'fill' : undefined}
            />
          ) : null}
        </Box>
        <Box sx={{ flex: '1 1 auto' }}>
          <Typography
            component="span"
            sx={{
              color: 'inherit',
              fontSize: '0.875rem',
              fontWeight: 500,
              lineHeight: '28px'
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </li>
  );
}

export default SideNav;
