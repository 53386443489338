import { useMemo, useState } from 'react';
import { Box, Modal, Tab, Tabs, Typography } from '@mui/material';
import ManageWorkspacesIcon from '@/assets/icons/manage-workspaces.svg';
import {
  MANAGE_WORKSPACE_TABS,
  WORKSPACE_BLOCK_MODES
} from '@/components/manageWorkspaceMenu/const';
import { useDeleteWorkspaceMutation, useLeaveWorkspaceMutation } from '@/services/api/workspaces';
import { useInvalidateUserInfo } from '@/hooks/useInvalidateUserInfo';
import { useAlert } from '@/contexts/AlertContext';
import { getWorkspaceOwnerName } from '@/utils/workspaces';
import { WORKSPACE_ROLES } from '@/const/workspace';
import {
  ManageWorkspaceMenuItem,
  ManageWorkspaceModalProps,
  ManageWorkspaceTab,
  WorkspaceInviteItem
} from '@/components/manageWorkspaceMenu/type';
import useSession from '@/hooks/useSession';
import { Auth0User } from '@/types';
import {
  BodyWrapper,
  DescriptionText,
  HeadlineWrapper,
  ImageWrapper,
  ModalContent,
  ModalWrapper,
  NotFoundText
} from './manageModal.styled';
import WorkspaceModalTab from '@/components/manageWorkspaceMenu/workspaceModalTab';
import JoinedWorkspace from '@/components/manageWorkspaceMenu/WorkspaceBlock/JoinedWorkspace';
import WorkspaceBlockTemplate from '@/components/manageWorkspaceMenu/WorkspaceBlock/WorkspaceBlockTemplate';
import InvitedWorkspace from '@/components/manageWorkspaceMenu/WorkspaceBlock/InvitedWorkspace';
import Button from '@/components/buttons/Button';
import { usePostAcceptInviteMutation } from '@/services/api/invitations';

const ManageWorkspaceModal = ({
  isOpen,
  setIsOpen,
  workspaces,
  invitations
}: ManageWorkspaceModalProps) => {
  const { user } = useSession();
  const { setAlert } = useAlert();
  const [onDeleteWorkspace] = useDeleteWorkspaceMutation();
  const [onLeaveWorkspace] = useLeaveWorkspaceMutation();
  const [onJoinWorkspace] = usePostAcceptInviteMutation();
  const invalidateUserInfo = useInvalidateUserInfo();

  const [tabValue, setTabValue] = useState<ManageWorkspaceTab>(MANAGE_WORKSPACE_TABS.JOINED);
  const [isCreateMode, setIsCreateMode] = useState(false);

  const workspaceItems: ManageWorkspaceMenuItem[] = useMemo(
    () =>
      workspaces.map(({ name, id, owner, role }) => ({
        name: name || 'Not resolved workspace',
        id,
        owner: getWorkspaceOwnerName(owner),
        isOwner: role === WORKSPACE_ROLES.OWNER
      })),
    [workspaces]
  );

  const invitationItems: WorkspaceInviteItem[] = useMemo(
    () =>
      invitations
        .filter(({ accepted }) => !accepted)
        .map(({ id, token, workspace }) => ({
          name: workspace.name || 'Not resolved workspace',
          id,
          token,
          owner: getWorkspaceOwnerName(workspace.owner)
        })),
    [invitations]
  );

  const handleOpen = (open: boolean = true) => {
    setIsOpen && setIsOpen(open);
  };

  const handleClose = () => {
    handleOpen(false);
    setIsCreateMode(false);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: ManageWorkspaceTab) => {
    setTabValue(newValue);
  };

  const toggleCreateMode = () => {
    setIsCreateMode((prev: boolean) => !prev);
  };

  const handleDeleteWorkspace = async (id: number, isOwner: boolean) => {
    try {
      if (isOwner) {
        await onDeleteWorkspace({ id });
      } else {
        await onLeaveWorkspace({ id });
      }
      await invalidateUserInfo();
    } catch (error) {
      setAlert({ type: 'error', description: 'Error deleting workspace' });
      console.error('Error deleting workspace: ', error);
    }
  };

  const handleJoinWorkspace = async (token: string) => {
    try {
      await onJoinWorkspace({ token });
      await invalidateUserInfo();
    } catch (error) {
      setAlert({ type: 'error', description: 'Error joining workspace' });
      console.error('Error joining workspace: ', error);
    }
  };

  return (
    <Box>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalWrapper>
          <ModalContent elevation={12}>
            <HeadlineWrapper>
              <ImageWrapper>
                <img src={ManageWorkspacesIcon} alt="ManageWorkspacesIcon" />
              </ImageWrapper>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5">Manage Workspaces</Typography>
                <DescriptionText variant="body2">Here you can manage the workspace</DescriptionText>
              </Box>
            </HeadlineWrapper>
            <BodyWrapper>
              <Tabs value={tabValue} onChange={handleChangeTab} sx={{ width: '100%' }}>
                <Tab
                  label="JOINED"
                  id="manage-workspace-tab-0"
                  aria-controls="manage-workspace-tabpanel-0"
                  sx={{ width: '50%' }}
                />
                <Tab
                  label="REQUESTS"
                  id="manage-workspace-tab-1"
                  aria-controls="manage-workspace-tabpanel-1"
                  sx={{ width: '50%' }}
                />
              </Tabs>
              <WorkspaceModalTab
                isScroll={workspaceItems.length > 1}
                value={tabValue}
                index={MANAGE_WORKSPACE_TABS.JOINED}
              >
                {workspaceItems.map(props => {
                  return (
                    <JoinedWorkspace
                      key={props.id}
                      workspace={{ ...props }}
                      typeButton={props.isOwner ? 'DELETE' : 'LEAVE'}
                      onClick={() => handleDeleteWorkspace(props.id, props.isOwner)}
                      onModalClose={handleClose}
                    />
                  );
                })}

                {isCreateMode && (
                  <WorkspaceBlockTemplate
                    name=""
                    ownerName={getWorkspaceOwnerName(user as Auth0User)}
                    typeButton="SAVE"
                    onButtonClick={toggleCreateMode}
                    onCancelClick={() => setIsCreateMode(false)}
                    initialMode={WORKSPACE_BLOCK_MODES.CREATE}
                  />
                )}
                {!isCreateMode && (
                  <Button title="Create new workspace" onClick={toggleCreateMode} />
                )}
              </WorkspaceModalTab>
              <WorkspaceModalTab
                isScroll={invitationItems.length > 2}
                value={tabValue}
                index={MANAGE_WORKSPACE_TABS.REQUESTS}
              >
                {!invitationItems.length && (
                  <NotFoundText>There're no pending requests</NotFoundText>
                )}
                {invitationItems.map(props => {
                  return (
                    <InvitedWorkspace
                      key={props.id}
                      workspace={{ ...props }}
                      typeButton="JOIN"
                      onClick={() => handleJoinWorkspace(props.token)}
                    />
                  );
                })}
              </WorkspaceModalTab>
            </BodyWrapper>
          </ModalContent>
        </ModalWrapper>
      </Modal>
    </Box>
  );
};

export default ManageWorkspaceModal;
