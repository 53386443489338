import { webAuth } from '../../webAuth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setSession } from '@/slices';
import { RootState } from '@/types/store';

export const baseQuery = fetchBaseQuery({
  // TODO: Need delete comment after CORS issue will be fixed
  // baseUrl: `${'http://localhost:8081'}/v1`,
  baseUrl: `${process.env.REACT_APP_APP_API_SERVER_URL || 'http://localhost:8080'}/api/v1`,
  // credentials: 'include',
  prepareHeaders: async (headers, { getState }) => {
    const session = (getState() as RootState).session ?? {};

    if (session.accessToken) {
      headers.set('Access-Control-Allow-Credentials', 'true');
      headers.set('Authorization', `Bearer ${session.accessToken}`);
    }

    return headers;
  }
});

const accountingReportsApi = createApi({
  reducerPath: 'accountingReportsApi',
  baseQuery: async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
      webAuth.logout({
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECTION_URL
      });
      api.dispatch(setSession(undefined));
    }
    return result;
  },
  tagTypes: [],
  endpoints: () => ({})
});

export interface ResponceI<T> {
  data: T;
  messsage?: string | null;
}

export default accountingReportsApi;
