import { accountingReportsApi } from '../base';
import { EAccountingReportsApiPaths, EReportTemplatePaths } from '@/services/enums';
import { ReportDownloadI, ReportTemplateFileI } from './types';

const reportTemplatesApi = accountingReportsApi.injectEndpoints({
  endpoints: builder => ({
    getReportDownloadTemplate: builder.query<any, ReportDownloadI>({
      query: ({ companyId, platform, reportTemplateId }) => {
        return {
          url: `${EAccountingReportsApiPaths.REPORTS}/downloadReport`,
          method: 'GET',
          params: {
            companyId: companyId,
            platform: platform,
            reportTemplateId: reportTemplateId
          },
          responseHandler: (
            response: any //// it is file
          ) => response.blob().then((blob: any) => URL.createObjectURL(blob))
        };
      }
    }),
    putReportTemplateFile: builder.mutation<void, ReportTemplateFileI>({
      query: ({ id, file }) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);
        return {
          url: `${EReportTemplatePaths.REPORT_TEMPLATE}/${id}/upload`,
          method: 'PUT',
          body: bodyFormData
        };
      }
    })
  })
});

export const { useGetReportDownloadTemplateQuery, usePutReportTemplateFileMutation } =
  reportTemplatesApi;

export const uploadReportTemplate = async (id: number, file: File, accessToken: string) => {
  const baseUrl = `${process.env.REACT_APP_APP_API_SERVER_URL || 'http://localhost:8080'}/api/v1`;

  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  await fetch(`${baseUrl}${EReportTemplatePaths.REPORT_TEMPLATE}/${id}/upload`, {
    method: 'PUT',
    body: bodyFormData,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const {
  endpoints: { getReportDownloadTemplate }
} = reportTemplatesApi;
