import React from 'react';

function ArrowNarrowLeft({ fill, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke={fill ?? 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4 12h16m0 0l-6 6m6-6l-6-6"
      ></path>
    </svg>
  );
}

export default ArrowNarrowLeft;
