import React, { FC } from 'react';
import { ButtonProps } from '@mui/material';
import { ActiveButtonStyled } from '@/components/ui/activeButton/activeButton.styled';

export type TActiveButtonProps = {
  label?: string;
  active?: boolean;
  isDisabled?: boolean;
} & ButtonProps;

const ActiveButton: FC<TActiveButtonProps> = ({
  label,
  isDisabled,
  active,
  children,
  ...restProps
}) => {
  return (
    <ActiveButtonStyled
      style={{
        ...(active && {
          backgroundColor: '#0078C829',
          color: '#0078C8'
        })
      }}
      disabled={typeof isDisabled !== 'undefined' ? isDisabled : false}
      {...restProps}
    >
      {label || children}
    </ActiveButtonStyled>
  );
};

export default ActiveButton;
