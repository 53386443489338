import { Button, ButtonProps, styled } from '@mui/material';

export const DangerButtonStyled = styled(Button, {
  name: 'DangerButton',
  slot: 'Root'
})<ButtonProps>(() => ({
  borderRadius: '.75rem',
  padding: '.56rem 1.75rem',
  border: '1px solid #FFB8B8',
  textTransform: 'none',
  backgroundColor: '#FFDADA',
  '&:hover': {
    backgroundColor: '#FFCCCC',
    border: '1px solid #FFB8B8'
  }
}));
